import { Component } from '@angular/core';

@Component({
  selector: 'app-asset-family-details',
  templateUrl: './asset-family-details.component.html',
  styleUrl: './asset-family-details.component.scss'
})
export class AssetFamilyDetailsComponent {
  familyTypes = [
    { name: 'Knitting', value: '1' },
    { name: 'Dyeing', value: '2' },
    { name: 'Finishing', value: '3' }
  ];
}
