import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Asset } from '../models/Asset';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AssetSearchCommand } from '../models/commands/AssetSearchCommand';

@Injectable({
  providedIn: 'root',
})
export class AssetProxyService {
  private readonly baseUrl = environment.proxyUrls.assetApi + '/api/v1/asset';

  constructor(private readonly httpClient: HttpClient) {}

  getAssets(): Observable<Asset[]> {
    return this.httpClient.get<Asset[]>(`${this.baseUrl}/all`);
  }

  getAssetById(id: string): Observable<Asset> {
    return this.httpClient.get<Asset>(`${this.baseUrl}/${id}`);
  }

  upsertAsset(asset: Asset): Observable<Asset> {
    return this.httpClient.post<Asset>(`${this.baseUrl}`, asset);
  }

  searchAssets(AssetSearchCommand: AssetSearchCommand): Observable<Asset[]> {
    return this.httpClient.post<Asset[]>(`${this.baseUrl}/search`, AssetSearchCommand);
  }
}
