import {
  Component,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { BaseModalComponent } from 'card-software-library';
import { AssetCategory } from '../../../models/AssetCategory';
import { CategoryProxyService } from '../../../proxies/category.proxy.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-category-details-modal',
  templateUrl: './category-details-modal.component.html',
  styleUrl: './category-details-modal.component.scss',
})
export class CategoryDetailsModalComponent
  extends BaseModalComponent<AssetCategory | null>
  implements OnInit
{
  @Input() category: AssetCategory | null = null;

  @Output() categoryUpdated = new EventEmitter<AssetCategory>();

  constructor(
    injector: Injector,
    private readonly categoryProxyService: CategoryProxyService,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.formGroup.patchValue({
      name: this.category?.name || '',
      description: this.category?.description || '',
      createdBy: this.category?.createdBy || '',
      modifiedBy: this.category?.modifiedBy || '',
      modifiedDate: this.category?.modifiedDate || new Date(),
    });
  }

  name: FormControl<string | null> = new FormControl('', [Validators.required]);
  description: FormControl<string | null> = new FormControl('', [
    Validators.required,
  ]);
  createdBy: FormControl<string | null> = new FormControl('');
  modifiedBy: FormControl<string | null> = new FormControl('');
  modifiedDate: FormControl<Date> = new FormControl(new Date(), {
    nonNullable: true,
  });

  formGroup: FormGroup = new FormGroup({
    name: this.name,
    description: this.description,
    createdBy: this.createdBy,
    modifiedBy: this.modifiedBy,
    modifiedDate: this.modifiedDate,
  });

  onClose() {
    this.close({ action: 'close', data: null });
  }

  onSave() {
    if (this.formGroup.valid) {
      const currentDate = new Date();
      this.modifiedDate.setValue(currentDate);

      const category: AssetCategory = {
        id: this.category?.id ?? '',
        name: this.name.value ?? '',
        description: this.description.value ?? '',
        createdBy: this.createdBy.value ?? '',
        modifiedBy: this.modifiedBy.value ?? '',
        modifiedDate: currentDate,
      };

      this.categoryProxyService
        .upsertCategory(category)
        .subscribe((updatedCategory) => {
          this.categoryUpdated.emit(updatedCategory);
          this.close({ action: 'save', data: updatedCategory });
        });
    }
  }
}
