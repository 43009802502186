<header class="row header">
  <h2>{{ type?.id ? 'Edit Processes' : 'Add Processes' }}</h2>
  <button (click)="onClose()" class="square mainAction">
    <span class="material-icons white text-white">close</span>
  </button>
</header>

<section class="content">
  <div class="row">
    <button (click)="openAll()" class="open-all-button">Open All</button>
    <button (click)="closeAll()" class="close-all-button">Close All</button>
  </div>

  <div *ngIf="assetProcesses.value.length === 0" class="empty-message">
    <p>No processes added. Click "Add Process" to start.</p>
  </div>

  <ul *ngFor="let process of assetProcesses.value; let i = index" class="process-list">
    <li>
      <div class="process-header">
        <button (click)="toggleSubProcess(process.id)" class="square">
          <span class="material-icons toggle-arrow">
            {{ showProcessesDict[process.id] ? 'expand_less' : 'expand_more' }}
          </span>
        </button>

        <input  class="p-inputtext p-component p-filled" type="text" [value]="process.name" (change)="updateProcessName(i, getInputValue($event))" placeholder="Process Name" />

        <button (click)="deleteProcess(i)" class="square">
          <span class="material-icons">close</span>
        </button>
      </div>

      <div *ngIf="showProcessesDict[process.id]" class="subprocess-container">
        <ul class="subprocess-list">
          <li *ngFor="let subProcess of process.subProcesses; let j = index">
            <div class="subprocess-header">
              <button (click)="toggleSubSubProcess(subProcess.id)" class="square me-2">
                <span class="material-icons toggle-arrow">
                  {{ showSubProcessesDict[subProcess.id] ? 'expand_less' : 'expand_more' }}
                </span>
              </button>

              <input
                class="p-inputtext p-component p-filled"
                type="text"
                [value]="subProcess.name"
                (change)="updateSubProcessName(i, j, getInputValue($event))"
                placeholder="Subprocess Name"
              />

              <button (click)="deleteSubProcess(i, j)" class="square ms-2">
                <span class="material-icons">close</span>
              </button>
            </div>

            <div *ngIf="showSubProcessesDict[subProcess.id]" class="nested-subprocess-container">
              <ul class="nested-subprocess-list">
                <li *ngFor="let nestedSubProcess of subProcess.subProcesses; let k = index">
                  <div class="nested-subprocess-header">
                    <input
                      class="p-inputtext p-component p-filled"
                      type="text"
                      [value]="nestedSubProcess.name"
                      (change)="updateNestedSubProcessName(i, j, k, getInputValue($event))"
                      placeholder="Nested Subprocess Name"
                    />

                    <button (click)="deleteNestedSubProcess(i, j, k)" class="square ms-2">
                      <span class="material-icons">close</span>
                    </button>
                  </div>
                </li>
                <li class="add-nested-subprocess">
                  <button (click)="addNestedSubProcess(i, j)">Add Gran Child Process</button>
                </li>
              </ul>
            </div>
          </li>
          <li class="add-subprocess">
            <button class="mainAction" (click)="addSubProcess(i)">Add Child Process</button>
          </li>
        </ul>
      </div>
    </li>
  </ul>

  <button class="add-process" (click)="addProcess()">Add Process</button>
</section>

<footer>
  <button class="mainAction" (click)="onSave()" [disabled]="formTypeGroup.invalid" type="submit">Save</button>
  <button (click)="onClose()">Cancel</button>
</footer>
