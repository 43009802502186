import { Component, Injector, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { BaseModalComponent } from 'card-software-library';
import { AssetModel } from '../../../models/AssetModel';
import { ModelProxyService } from '../../../proxies/model.proxy.service';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { TypeProxyService } from '../../../proxies/types.proxy.service';
import { AssetType } from '../../../models/AssetType';
import { AssetModelProperty } from '../../../models/AssetModelProperty';
import { of } from 'rxjs';
import _ from 'lodash';
import { AutoCompleteCompleteEvent } from 'primeng/autocomplete';

@Component({
  selector: 'app-model-details-modal',
  templateUrl: './model-details-modal.component.html',
  styleUrl: './model-details-modal.component.scss',
})
export class ModelDetailsModalComponent extends BaseModalComponent<AssetModel | null> implements OnInit {
  @Input() model: AssetModel | null = null;
  @Input() types: AssetType[] = [];
  @Input() modelProperties: AssetModelProperty[] = [];

  selectedModelProperties: AssetModelProperty[] = [];

  modelProperty: FormControl<AssetModelProperty | null> = new FormControl(null);
  assetType: FormControl<AssetType | null> = new FormControl(null, [Validators.required]);
  name: FormControl<string | null> = new FormControl('', [Validators.required]);
  description: FormControl<string | null> = new FormControl('', [Validators.required]);
  propertiesReference: FormControl<string[] | null> = new FormControl([]);
  createdBy: FormControl<string | null> = new FormControl('');
  modifiedBy: FormControl<string | null> = new FormControl('');
  modifiedDate: FormControl<Date> = new FormControl(new Date(), {
    nonNullable: true,
  });

  //Arrays for autocomplete
  filteredTypes:AssetType[] = [];
  filteredModelProperties:AssetModelProperty[] = [];


  formModelGroup: FormGroup = new FormGroup({
    name: this.name,
    description: this.description,
    assetType: this.assetType,
    modelProperty: this.modelProperty,
    createdBy: this.createdBy,
    modifiedBy: this.modifiedBy,
    modifiedDate: this.modifiedDate,
  });

  constructor(
    injector: Injector,
    private readonly modelProxyService: ModelProxyService,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.formModelGroup.patchValue({
      id: this.model?.id || '',
      name: this.model?.name || '',
      description: this.model?.description || '',
      assetType: this.types.find((type) => type.id === this.model?.assetType?.id) || null,
      modelProperty: null,
      createdBy: this.model?.createdBy || '',
      modifiedBy: this.model?.modifiedBy || '',
      modifiedDate: this.model?.modifiedDate || new Date(),
    });

    this.modelProperty.valueChanges.subscribe((value) => {
      if(typeof value === 'string')
        return;
      if (value) {
        this.onPropertySelected(value);
      }
    });

    this.selectedModelProperties = this.modelProperties.filter((property) =>
      this.model?.modelProperties?.some((p) => p.id === property.id),
    );
  }

  
  typesTypeahead(event: AutoCompleteCompleteEvent) {
  const query = event.query.toLowerCase();
  this.filteredTypes  = this.types
    .filter(t=>t.name.toLowerCase().includes(query)) 
    .sort((a, b) => a.name.localeCompare(b.name)) 
    .slice(0, 8); 
}
  

modelPropertyTypeahead(event: AutoCompleteCompleteEvent) {
  const query = event.query.toLowerCase();
  
  const availableProperties = this.modelProperties.filter(
    (property) => !this.selectedModelProperties.some((selected) => selected.id === property.id)
  );
  
  this.filteredModelProperties = availableProperties
    .filter(property => property.name.toLowerCase().includes(query))
    .sort((a, b) => a.name.localeCompare(b.name)) 
    .slice(0, 8);
}
  
  onPropertySelected(selectedProperty: any) {
    if (!this.selectedModelProperties.some((p) => p.id === selectedProperty.id)) {
      this.selectedModelProperties.push(selectedProperty);

      this.modelProperties = this.modelProperties.filter((property) => property.id !== selectedProperty.id);
      this.modelProperty.reset();
    }
  }

  removeProperty(property: AssetModelProperty) {
    this.selectedModelProperties = this.selectedModelProperties.filter((p) => p.id !== property.id);
  }

  onClose() {
    this.name.value;
    this.formModelGroup.controls['name'].value;
    this.close({ action: 'close', data: null });
  }

  isButtonDisabled(): boolean {
    return this.formModelGroup.invalid || typeof this.assetType.value === 'string';
  }

  onSave() {
    if (this.formModelGroup.valid) {
      const currentDate = new Date();
      this.modifiedDate.setValue(currentDate);

      const model: AssetModel = {
        id: this.model?.id ?? '',
        name: this.name.value ?? '',
        description: this.description.value ?? '',
        assetTypeReference: this.assetType?.value?.id ?? '',
        modelPropertiesReferences: this.selectedModelProperties.map((property) => property.id),
        excludedProcessIds: [],
        createdBy: this.createdBy.value ?? '',
        modifiedBy: this.modifiedBy.value ?? '',
        modifiedDate: currentDate,
      };

      this.modelProxyService.upsertModel(model).subscribe({
        next: (response) => {
          // response.modelProperties = this.model?.modelProperties ?? [];
          response.modelProperties = this.selectedModelProperties ?? [];
          response.assetType = this.assetType?.value ?? undefined;
          this.close({ action: 'save', data: response });
        },
        error: (error) => {
          console.log('Error saving model', error);
        },
      });
    }
  }
}
