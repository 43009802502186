<main>
  <div class="content">
    <div class="grid grid-cols-12">
      <div class="col-span-12 md:col-span-4">
        <div class="brand__wrapper">
          <div class="app-icon"></div> 
          Sierra Asset
        </div>
        <h1>Login</h1>
        <form action="" [formGroup]="loginFormGroup">
          <div class="flow">
            <div class="form-box">
              <label>User Name</label>
              <input pInputText class="w-full" type="email" id="userNameLogin" formControlName="username" />              
            </div>
            <div class="form-box">
              <label for="">Password</label>
              <p-password class="w-full" id="passwordLogin" formControlName="password" [toggleMask]="true" [feedback]="false" />
            </div>
            <div class="row">
              <button class="mainAction" (click)="login()">Login</button>
              <p class="error" *ngIf="!isLoading && wrongCredentials">Sorry, login information provided is incorrect.</p>
            </div>
            <label class="info__label">If you have forgotten your username or password please contact info&#64;card-software.com</label>
          </div>
        </form>
      </div>
    </div>
  </div>
</main>
