//Replicate for stage and prod
export const environment = {
  production: false,
  environment: 'dev',
  title: 'Sierra Asset',
  name: 'sierra-asset',
  knittingMachineCategoryId: '673c07b2a128eab872eb6c2d',
  dyeingMachineCategoryId: '67ad22cfee06eac3505f9866',
  proxyUrls: {
    assetApi: 'https://asset.api.dev.sierra-erp.com',
    itemApi: 'https://item.api.dev.sierra-erp.com',
    userMasterApi: 'https://user.api.dev.sierra-erp.com',
  },
};
