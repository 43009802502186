<main>
  <loading-overlay [isLoading]="isLoading > 0"></loading-overlay>
  <div class="content">
    <h1 class="line float__button">
      Sites
      <div class="button__wrapper">
        <button class="flat" (click)="openSiteDetailsModal()">
          <span class="material-icons">add</span>
          Add
        </button>
      </div>
    </h1>

    <div class="grid grid-cols-12 grid__gap__2">
      <div class="col-span-12">
        <p-table [value]="sites" [paginator]="true" [rows]="5" [rowsPerPageOptions]="[5, 10, 20]">
          <ng-template pTemplate="header">
            <tr class="primary__header">
              <td pSortableColumn="code">Code <p-sortIcon field="code" /></td>
              <td pSortableColumn="name">Name <p-sortIcon field="name" /></td>
              <td pSortableColumn="description">Description <p-sortIcon field="description" /></td>
              <td pSortableColumn="isActive">Activity <p-sortIcon field="isActive" /></td>
              <td pSortableColumn="externalSite">External Site <p-sortIcon field="externalSite" /></td>
              <td pSortableColumn="countryId">Country ID <p-sortIcon field="countryId" /></td>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-site>
            <tr>
              <td>
                <a (click)="openSiteDetailsModal(site)">{{ site.code }}</a>
              </td>
              <td>{{ site.name }}</td>
              <td>{{ site.description }}</td>
              <td>{{ site.isActive }}</td>
              <td>{{ site.externalSite }}</td>
              <td>{{ site.countryId }}</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</main>
