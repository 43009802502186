<header class="row header">
  <h2>{{ model?.id ? 'Edit Model' : 'Add Model' }}</h2>
  <button (click)="onClose()" class="square mainAction">
    <span class="material-icons white text-white">close</span>
  </button>
</header>
<section class="content">
  <form [formGroup]="formModelGroup">
    <!-- Name -->
    <div class="form-box">
      <label for="name" ariaRequired="true">Name</label>
      <input pInputText type="text" id="name" formControlName="name" class="w-full" />
    </div>
    <!-- Description -->
    <div class="form-box">
      <label for="description" ariaRequired="true">Description</label>
      <input pInputText type="text" id="description" formControlName="description" class="w-full" />
    </div>
    <!-- Type -->
    <div class="form-box">
      <label for="assetType" ariaRequired="true">Type</label>
      <p-autocomplete
        formControlName="assetType"
        optionLabel="name"
        [suggestions]="filteredTypes"
        (completeMethod)="typesTypeahead($event)"
        class="w-full"
      />
    </div>
    <!-- Model Property -->
    <div class="form-box">
      <label for="propertiesReference">Model Property</label>
      <p-autocomplete
        formControlName="modelProperty"
        optionLabel="name"
        [suggestions]="filteredModelProperties"
        (completeMethod)="modelPropertyTypeahead($event)"
        class="w-full"
      />
    </div>

    <ul class="flex flex-col gap-2">
      <li
        *ngFor="let property of selectedModelProperties"
        class="justify-between max-w-fit flex items-center font-medium px-2 py-1 rounded-full border-2"
      >
        <p class="px-2">{{ property.name }}</p>
        <button (click)="removeProperty(property)" class="border-none"><span class="material-icons">close</span></button>
      </li>
    </ul>

    <!-- Hidden until JWT are ready -->
    <!-- <h3 class="line">History</h3>
    <div class="form-box">
      <label for="createdBy">Created By</label>
      <p>{{ model?.createdBy }}</p>
    </div>
    <div class="form-box">
      <label for="modifiedBy">Modified By</label>
      <p>{{ model?.modifiedBy }}</p>
    </div>
    <div class="form-box">
      <label for="modifiedDate"> Modified Date </label>
      <p>{{ modifiedDate.value | date: 'yyyy-MM-dd' }}</p>
    </div> -->
  </form>
</section>
<footer>
  <button class="mainAction" (click)="onSave()" [disabled]="isButtonDisabled()" type="submit">Save</button>
  <button (click)="onClose()">Cancel</button>
</footer>
