import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { DialogModule } from '@angular/cdk/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AssetDetailsComponent } from './components/asset-details/asset-details.component';
import { AssetFamilyDetailsComponent } from './components/asset-management-module/asset-family-details/asset-family-details.component';
import { HttpClientModule, provideHttpClient, withInterceptors } from '@angular/common/http';
import { AssetsComponent } from './components/assets/assets.component';
import { SitesComponent } from './components/sites/sites.component';
import { SiteDetailsModalComponent } from './components/_modals/site-details-modal/site-details-modal.component';
import { CardSoftwareLibraryModule } from 'card-software-library';
import { CommonModule } from '@angular/common';
import { AssetsDetailsComponent } from './components/assets-details/assets-details.component';
import { CategoryDetailsModalComponent } from './components/_modals/category-details-modal/category-details-modal.component';
import { TypeDetailsModalComponent } from './components/_modals/type-details-modal/type-details-modal.component';
import { TypeDetailsProcessModalComponent } from './components/_modals/type-details-process-modal/type-details-process-modal.component';
import { ModelDetailsModalComponent } from './components/_modals/model-details-modal/model-details-modal.component';
import { ModelPropertyDetailsModalComponent } from './components/_modals/model-property-details-modal/model-property-details-modal.component';
import { ModelDetailsProcessModalComponent } from './components/_modals/model-details-process-modal/model-details-process-modal.component';
import { authInterceptor } from './auth.interceptor';
import { LoginComponent } from './components/login/login.component';
//Prime NG
import { PrimengModule } from './primeng/primeng.module';
import Aura from '@primeng/themes/aura';
import { providePrimeNG } from 'primeng/config';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';


@NgModule({
  declarations: [
    AppComponent,
    AssetDetailsComponent,
    AssetFamilyDetailsComponent,
    AssetsComponent,
    SitesComponent,
    SiteDetailsModalComponent,
    AssetsDetailsComponent,
    CategoryDetailsModalComponent,
    TypeDetailsModalComponent,
    TypeDetailsProcessModalComponent,
    ModelDetailsModalComponent,
    ModelPropertyDetailsModalComponent,
    ModelDetailsProcessModalComponent,
    LoginComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    CardSoftwareLibraryModule,
    CommonModule,
    DialogModule,
    FormsModule,
    MatAutocompleteModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatTooltipModule,
    ReactiveFormsModule,
    MatIconModule,
    PrimengModule
  ],
  providers: [
    provideHttpClient(withInterceptors([authInterceptor])),
    provideAnimationsAsync(),
    providePrimeNG({ 
      theme: {
          preset: Aura,
          options: {
            darkModeSelector: false,
          }
      }
  })
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
