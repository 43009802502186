<header class="row header">
  <h2>{{ site?.code ? 'Edit Site' : 'Add Site' }}</h2>
  <button (click)="onClose()" class="square mainAction">
    <span class="material-icons white text-white">close</span>
  </button>
</header>
<section class="content">
  <form [formGroup]="fromGroup">
    <!-- Site Code -->
    <div class="form-box">
      <label for="code">Site Code</label>
      <input pInputText type="text" id="code" formControlName="code" class="w-full" />
    </div>
    <!-- Site Name -->
    <div class="form-box">
      <label for="name">Site Name</label>
      <input pInputText type="text" id="name" formControlName="name" class="w-full" />
    </div>
    <!-- Description -->
    <div class="form-box">
      <label for="description">Description</label>
      <textarea pTextarea id="description" formControlName="description" class="w-full"></textarea>
    </div>
    <!-- Country ID -->
    <div class="form-box">
      <label for="countryId">Country ID</label>
      <input pInputText type="text" id="countryId" formControlName="countryId" class="w-full" />
    </div>
  </form>
</section>
<footer>
  <button class="mainAction" (click)="onSave()" type="submit">Save</button>
  <button (click)="onClose()">Cancel</button>
</footer>
