<main>
  <div class="content" *ngIf="asset">
    <h1>{{ asset.assetId }} &bull; Mayer Cie Single Knit</h1>
    <p class="informative">Asset over $5,000</p>
    <section>
      <h3 class="line">General</h3>
      <div class="form-box">
        <label for="">Code</label>
        <p>K-23</p>
      </div>
      <div class="row">
        <div class="form-box">
          <label for="createdBy">Asset Type</label>
          <input type="text" value="Type of asset" />
        </div>
        <div class="form-box">
          <label for="createdBy">Asset Family</label>
          <select>
            <option value="1">Family 1</option>
            <option value="2">Family 2</option>
            <option value="3">Family 3</option>
          </select>
        </div>
        <div class="form-box">
          <label for="brand">Brand</label>
          <input type="text" id="model" name="model" value="Well Rich" />
        </div>
        <div class="form-box">
          <label for="model">Model</label>
          <input type="text" id="model" name="model" value="2022" />
        </div>
        <div class="form-box">
          <label for="createdBy">Date Acquired</label>
          <p>2022-24-08</p>
        </div>
      </div>
    </section>
    <section>
      <h3 class="line">Location</h3>
      <div class="row">
        <div class="form-box">
          <label for="">Facility Id</label>
          <p>NT</p>
        </div>
        <div class="form-box">
          <label for="">Floor Location</label>
          <p>Knitting</p>
        </div>
      </div>
    </section>
    <section>
      <h3 class="line">Knitting Info</h3>
      <p class="informative">
        (based on the machine type, we will know if to display knitting info or
        dyeing info etc.)
      </p>

      <p class="question">
        Question? Should we allow the user to change properties?
      </p>
      <p>
        This information is being pulled from the Asset Family. To edit contact
        ... OR if admin <a>Family 1</a>
      </p>
      <div class="row">
        <div class="form-box">
          <label for="dia">Dia</label>
          <input type="text" id="dia" name="dia" value="30" />
        </div>

        <div class="form-box">
          <label for="gauge">Gauge</label>
          <input type="text" id="gauge" name="gauge" value="18" />
        </div>

        <div class="form-box">
          <label for="needles">Needles</label>
          <input type="text" id="needles" name="needles" value="3360" />
        </div>

        <div class="form-box">
          <label for="feeders">Feeders</label>
          <input type="text" id="feeders" name="feeders" value="60" />
        </div>

        <div class="form-box">
          <label for="yarnTension">Yarn Tension cN</label>
          <input type="text" id="yarnTension" name="yarnTension" value="10" />
        </div>

        <div class="form-box">
          <label for="machineRev">Machine Rev</label>
          <input type="text" id="machineRev" name="machineRev" value="2873" />
        </div>

        <div class="form-box">
          <label for="machineRotation">Machine Rotation</label>
          <input
            type="text"
            id="machineRotation"
            name="machineRotation"
            value="Anti Clock"
          />
        </div>

        <div class="form-box">
          <label for="rpm">RPM</label>
          <input type="text" id="rpm" name="rpm" value="20" />
        </div>
      </div>
    </section>
    <section>
      <h3 class="line">Capacity</h3>
      <p class="informative">
        This capacity info is to help planning team and future us know how much
        a machine can handle.
      </p>
      <div class="row">
        <div class="form-box">
          <label for="shift">Shift</label>
          <input type="text" id="shift" name="shift" value="1" />
        </div>
        <div class="form-box">
          <label for="">M/C Rev</label>
          <input type="text" id="mcRev" name="mcRev" value="1,000" />
        </div>
      </div>
      <div class="row">
        <div class="form-box">
          <label for="shift">Shift</label>
          <input type="text" id="shift" name="shift" value="2" />
        </div>
        <div class="form-box">
          <label for="">M/C Rev</label>
          <input type="text" id="mcRev" name="mcRev" value="1,000" />
        </div>
      </div>
      <div class="row">Total Capacity: 2,000</div>
    </section>
  </div>
</main>
