import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { LoginBody } from '../models/user-master/commands/login-body';
import { TokenBody } from '../models/user-master/token-body';

@Injectable({
  providedIn: 'root',
})
export class UserMasterProxyService {
  private readonly baseUrl = environment.proxyUrls.userMasterApi + '/api/v1/authentication';
  constructor(private readonly httpClient: HttpClient) {}

  login(loginBody: LoginBody): Observable<TokenBody> {
    return this.httpClient.post<any>(`${this.baseUrl}/login`, loginBody);
  }
}
