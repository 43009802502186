import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AssetProxyService } from '../../proxies/asset.proxy.service';
import { Asset } from '../../models/Asset';

@Component({
  selector: 'app-asset-details',
  templateUrl: './asset-details.component.html',
  styleUrls: ['./asset-details.component.scss'], // Fixing typo: styleUrl -> styleUrls
})
export class AssetDetailsComponent implements OnInit {
  assetId!: string;
  asset!: Asset;

  constructor(
    private readonly assetProxyService: AssetProxyService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
  ) {}

  ngOnInit() {
    // Extract the query parameter
    this.route.queryParams.subscribe((params) => {
      this.assetId = params['assetId'];
      console.log('Asset ID:', this.assetId);

      // Fetch assets using the assetId if necessary
      if (this.assetId) {
        this.assetProxyService.getAssetById(this.assetId).subscribe((res) => {
          this.asset = res;
        });
      } else {
        console.log('Asset ID is not provided');
        this.router.navigate(['/assets']);
      }
    });
  }
}
