import { Component, OnInit } from '@angular/core';
import { CategoryProxyService } from '../../proxies/category.proxy.service';
import { ModalService } from 'card-software-library';
import { AssetCategory } from '../../models/AssetCategory';
import { CategoryDetailsModalComponent } from '../_modals/category-details-modal/category-details-modal.component';
import { TypeProxyService } from '../../proxies/types.proxy.service';
import { AssetType } from '../../models/AssetType';
import { TypeDetailsModalComponent } from '../_modals/type-details-modal/type-details-modal.component';
import { ModelProxyService } from '../../proxies/model.proxy.service';
import { AssetModel } from '../../models/AssetModel';
import { ModelDetailsModalComponent } from '../_modals/model-details-modal/model-details-modal.component';
import { ModelPropertyProxyService } from '../../proxies/model-property.proxy.service';
import { AssetModelProperty } from '../../models/AssetModelProperty';
import { ModelPropertyDetailsModalComponent } from '../_modals/model-property-details-modal/model-property-details-modal.component';
import { ActivatedRoute } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { TypeDetailsProcessModalComponent } from '../_modals/type-details-process-modal/type-details-process-modal.component';
import { ModelDetailsProcessModalComponent } from '../_modals/model-details-process-modal/model-details-process-modal.component';
@Component({
  selector: 'app-assets-details',
  templateUrl: './assets-details.component.html',
  styleUrls: ['./assets-details.component.scss'],
})
export class AssetsDetailsComponent implements OnInit {
  isLoading = 0;

  categories: AssetCategory[] = [];
  filteredCategories: AssetCategory[] = [];

  types: AssetType[] = [];
  filteredTypes: AssetType[] = [];

  models: AssetModel[] = [];
  filteredModels: AssetModel[] = [];

  modelProperties: AssetModelProperty[] = [];
  filteredModelProperties: AssetModelProperty[] = [];

  activeIndex = 0;

  constructor(
    private readonly categoryProxyService: CategoryProxyService,
    private readonly typesProxyService: TypeProxyService,
    private readonly modelProxyService: ModelProxyService,
    private readonly modalService: ModalService,
    private readonly modelPropertyProxyService: ModelPropertyProxyService,
    private readonly route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.isLoading++;
    forkJoin([this.loadCategories(), this.loadTypes(), this.loadModels(), this.loadModelProperties()]).subscribe(
      ([categories, types, models, modelProperties]) => {
        this.isLoading--;
        this.categories = categories;
        this.filteredCategories = [...categories];

        this.types = types;
        this.filteredTypes = [...types];

        this.models = models;
        this.filteredModels = [...models];

        this.modelProperties = modelProperties;
        this.filteredModelProperties = [...modelProperties];

        this.route.queryParams.subscribe((params) => {
          if (params['addNewType'] && params['categoryReference']) {
            const categoryReference = params['categoryReference'];
            this.activeIndex = 1;
            this.openTypeDetailsModal({
              id: '',
              name: '',
              description: '',
              assetCategory: {
                id: categoryReference,
                name: 'ignore',
                description: 'ignore',
              },
              assetCategoryReference: categoryReference,
              assetProcesses: [],
            });
          }
          if (params['addNewModel'] && params['typeReference']) {
            const typeReference = params['typeReference'];
            const categoryReferenece = this.types.find((type) => type.id === typeReference)?.assetCategoryReference;
            this.activeIndex = 2;
            this.openModelDetailsModal({
              id: '',
              name: '',
              description: '',
              assetType: {
                id: typeReference,
                name: '',
                description: '',
                assetCategory: {
                  id: '',
                  name: '',
                  description: '',
                },
                assetCategoryReference: categoryReferenece ?? '',
                assetProcesses: [],
              },
              assetTypeReference: typeReference,
              excludedProcessIds: [],
              modelProperties: [],
              modelPropertiesReferences: [],
            });
          }
        });
      },
    );
  }

  loadCategories(): Observable<AssetCategory[]> {
    return this.categoryProxyService.getCategories();
  }

  loadTypes(): Observable<AssetType[]> {
    return this.typesProxyService.getTypes();
  }

  loadModels(): Observable<AssetModel[]> {
    return this.modelProxyService.getModels();
  }

  loadModelProperties(): Observable<AssetModelProperty[]> {
    return this.modelPropertyProxyService.getModels();
  }

  openCategoryDetailsModal(category?: AssetCategory) {
    this.modalService.open(CategoryDetailsModalComponent, {}, { category: category ?? null }).subscribe((result) => {
      if (result?.action === 'save' && result.data) {
        this.onCategoryUpdated(result.data);
      }
    });
  }

  openTypeDetailsModal(type?: AssetType) {
    this.modalService.open(TypeDetailsModalComponent, {}, { type: type ?? null, categories: this.categories }).subscribe((result) => {
      if (result?.action === 'save' && result.data) {
        this.onTypeUpdated(result.data);
      }
    });
  }

  openTypeDetailsProcessModal(type?: AssetType) {
    // Open the modal with the same data as in openTypeDetailsModal
    this.modalService.open(TypeDetailsProcessModalComponent, {}, { type: type, categories: this.categories }).subscribe((result) => {
      if (result?.action === 'save' && result.data) {
        this.onTypeUpdated(result.data);
      }
    });
  }

  openModelDetailsProcessModal(model?: AssetModel) {
    // Open the modal with the same data as in openTypeDetailsModal
    this.modalService.open(ModelDetailsProcessModalComponent, {}, { model: model }).subscribe((result) => {
      if (result?.action === 'save' && result.data) {
        this.onModelUpdated(result.data);
      }
    });
  }

  openModelDetailsModal(model?: AssetModel) {
    this.modalService
      .open(ModelDetailsModalComponent, {}, { model: model ?? null, modelProperties: this.modelProperties, types: this.types })
      .subscribe((result) => {
        if (result?.action === 'save' && result.data) {
          this.onModelUpdated(result.data);
        }
      });
  }

  openModelPropertiesModal(assetModelProperty?: AssetModelProperty) {
    this.modalService
      .open(ModelPropertyDetailsModalComponent, {}, { assetModelProperty: assetModelProperty ?? null })
      .subscribe((result) => {
        if (result?.action === 'save' && result.data) {
          this.onModelPropertyUpdated(result.data);
        }
      });
  }

  onCategoryUpdated(updatedCategory: AssetCategory) {
    const index = this.categories.findIndex((category) => category.id === updatedCategory.id);
    if (index !== -1) {
      this.categories[index] = updatedCategory;
    } else {
      this.categories.push(updatedCategory);
    }
    this.filteredCategories = [...this.categories];
  }

  onTypeUpdated(updatedType: AssetType) {
    const index = this.types.findIndex((type) => type.id === updatedType.id);
    if (index !== -1) {
      this.types[index] = updatedType;
    } else {
      this.types.push(updatedType);
    }
    this.filteredTypes = [...this.types];
  }

  onModelUpdated(updatedModel: AssetModel) {
    const index = this.models.findIndex((model) => model.id === updatedModel.id);
    if (index !== -1) {
      this.models[index] = updatedModel;
    } else {
      this.models.push(updatedModel);
    }
    this.filteredModels = [...this.models];
  }

  onModelPropertyUpdated(updatedModelProperty: AssetModelProperty) {
    const index = this.modelProperties.findIndex((modelProperty) => modelProperty.id === updatedModelProperty.id);
    if (index !== -1) {
      this.modelProperties[index] = updatedModelProperty;
    } else {
      this.modelProperties.push(updatedModelProperty);
    }
    this.filteredModelProperties = [...this.modelProperties];
  }

  searchCategory(term: string) {
    if (term.trim() === '') {
      this.filteredCategories = this.categories;
    } else {
      this.filteredCategories = this.categories.filter((category) => category.name.toLowerCase().includes(term.toLowerCase()));
    }
  }

  searchType(term: string) {
    if (term.trim() === '') {
      this.filteredTypes = this.types;
    } else {
      this.filteredTypes = this.types.filter((type) => type.name.toLowerCase().includes(term.toLowerCase()));
    }
  }

  searchModel(term: string) {
    if (term.trim() === '') {
      this.filteredModels = this.models;
    } else {
      this.filteredModels = this.models.filter((model) => model.name.toLowerCase().includes(term.toLowerCase()));
    }
  }

  searchModelProperty(term: string) {
    if (term.trim() === '') {
      this.filteredModelProperties = this.modelProperties;
    } else {
      this.filteredModelProperties = this.modelProperties.filter((modelProperty) =>
        modelProperty.name.toLowerCase().includes(term.toLowerCase()),
      );
    }
  }
}
