import { Component, OnInit } from '@angular/core';
import { AssetProxyService } from '../../proxies/asset.proxy.service';
import { Asset } from '../../models/Asset';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-assets',
  templateUrl: './assets.component.html',
  styleUrl: './assets.component.scss',
})
export class AssetsComponent implements OnInit {
  constructor(
    private readonly assetProxyService: AssetProxyService,
    private readonly router: Router,
  ) {}
  assets: Asset[] = [];
  searchTermControl = new FormControl('');
  isLoading = 0;
  ngOnInit() {
    this.search();
  }

  search() {
    this.isLoading++;
    this.assetProxyService.searchAssets({ assetId: this.searchTermControl.value ?? '' }).subscribe({
      next: (assets) => {
        this.assets = assets;
        this.isLoading--;
      },
      error: (error) => {
        console.error(error);
        this.isLoading--;
      },
    });
  }

  redirectToAssetDetails(assetId?: string) {
    this.router.navigate(['/asset-details'], { queryParams: { assetId } });
  }
}
