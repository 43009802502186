import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AssetModel } from '../models/AssetModel';

@Injectable({
  providedIn: 'root',
})
export class ModelProxyService {
  private readonly baseUrl = environment.proxyUrls.assetApi + '/api/v1/model';
  constructor(private readonly httpClient: HttpClient) {}

  getModels(): Observable<AssetModel[]> {
    return this.httpClient.get<AssetModel[]>(`${this.baseUrl}/all`);
  }

  upsertModel(model: AssetModel): Observable<AssetModel> {
    return this.httpClient.post<AssetModel>(`${this.baseUrl}`, model);
  }
}
