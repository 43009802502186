<main>
  <div class="content">
    <h1 class="line">Assets</h1>
    <div aria-label="Toolbar">
      <button class="mainAction">Add</button>
    </div>
    <div class="body filtered-list">
      <div class="filters">
        <h3>Filters</h3>
        <section>
          <form action="">
            <div class="form-box">
              <label for="">Asset Id</label><input type="text" />
            </div>
          </form>
        </section>
      </div>
      <div class="list">
        <table>
          <thead>
            <tr>
              <th>Asset Id</th>
              <th>Category</th>
              <th>Type</th>
              <th>Model</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let asset of assets">
              <td>
                <a (click)="redirectToAssetDetails(asset.assetId)">{{
                  asset.assetId
                }}</a>
              </td>
              <td>{{ asset.assetCategoryReference }}</td>
              <td>{{ asset.assetTypeReference }}</td>
              <td>{{ asset.assetModelReference }}</td>
              <td>{{ asset.description }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</main>
