import { Component, OnInit } from '@angular/core';
import { AssetProxyService } from '../../proxies/asset.proxy.service';
import { Asset } from '../../models/Asset';
import { Router } from '@angular/router';

@Component({
  selector: 'app-assets',
  templateUrl: './assets.component.html',
  styleUrl: './assets.component.scss',
})
export class AssetsComponent implements OnInit {
  constructor(
    private readonly assetProxyService: AssetProxyService,
    private readonly router: Router,
  ) {}
  assets: Asset[] = [];
  ngOnInit() {
    this.assetProxyService.getAssets().subscribe((assets) => {
      this.assets = assets;
    });
  }

  redirectToAssetDetails(assetId: string) {
    this.router.navigate(['/asset-details'], { queryParams: { assetId } });
  }
}
