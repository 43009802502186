import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { delay, Subject } from 'rxjs';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss',
})
export class SidebarComponent {
  static currentOpenSidebar = new Subject<number>();
  private static nextId = 0;

  @HostBinding('class.left') left = true;
  @HostBinding('class.right') right = false;

  @Output() expandedChange = new EventEmitter<boolean>();
  @Input() forceOverlay = false;

  private id;
  private _expanded = false;

  constructor() {
    this.id = SidebarComponent.getNewId();
    SidebarComponent.currentOpenSidebar.pipe(delay(0)).subscribe((openId) => {
      if (openId !== this.id) {
        this.expanded = false;
        this.expandedChange.emit(false);
      }
    });
  }

  get expanded() {
    return this._expanded;
  }

  @Input() set expanded(value: boolean) {
    this._expanded = value;
    if (this._expanded) {
      SidebarComponent.currentOpenSidebar.next(this.id);
    }
  }

  @Input() set side(value: 'left' | 'right') {
    this.left = value === 'left';
    this.right = value === 'right';
  }

  static getNewId() {
    const idToIssue = this.nextId;
    this.nextId++;
    return idToIssue;
  }
}
