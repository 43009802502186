<loading-overlay [isLoading]="isLoading > 0"></loading-overlay>
<main>
  <div class="content">
    <h1 class="line float__button">Assets Details</h1>

    <p-tabs value="0">
      <p-tablist>
        <p-tab value="0">Categories</p-tab>
        <p-tab value="1">Types</p-tab>
        <p-tab value="2">Models</p-tab>
        <p-tab value="3">Model Properties</p-tab>
      </p-tablist>
      <p-tabpanels>
        <p-tabpanel value="0">
          <div class="flow">
            <section>
              <div class="header-list">
                <div class="input-wrapper">
                  <p-iconfield>
                    <p-inputicon styleClass="pi pi-search" />
                    <input pInputText #searchCategoryInput type="text" (input)="searchCategory(searchCategoryInput.value)" />
                  </p-iconfield>
                </div>
                <button class="flat" (click)="openCategoryDetailsModal()">
                  <span class="material-icons">add</span>
                  Add
                </button>
              </div>
              <ul class="cs-pattern">
                <li *ngFor="let category of filteredCategories; let i = index" [ngClass]="{ 'container-list__item--green': i % 2 === 0 }">
                  <div class="">
                    <p>{{ category.name }}</p>
                  </div>
                  <div class="actions">
                    <button class="border-none" (click)="openCategoryDetailsModal(category)">
                      <span class="material-icons">edit</span>
                    </button>
                  </div>
                </li>
              </ul>
            </section>
          </div>
        </p-tabpanel>
        <p-tabpanel value="1">
          <div class="flow">
            <section>
              <div class="header-list">
                <div class="input-wrapper">
                  <p-iconfield>
                    <p-inputicon styleClass="pi pi-search" />
                    <input pInputText #searchTypeInput type="text" (input)="searchType(searchTypeInput.value)" />
                  </p-iconfield>
                </div>
                <button class="flat" (click)="openTypeDetailsModal()"><span class="material-icons">add</span> Add</button>
              </div>
              <ul class="cs-pattern">
                <li *ngFor="let type of filteredTypes; let i = index" [ngClass]="{ 'container-list__item--green': i % 2 === 0 }">
                  <div class="">
                    <p>{{ type.name }}</p>
                  </div>
                  <div class="actions">
                    <button class="border-none" (click)="openTypeDetailsModal(type)">
                      <span class="material-icons">edit</span>
                    </button>
                    <button class="border-none" (click)="openTypeDetailsProcessModal(type)">
                      <span class="material-icons">build</span>
                    </button>
                  </div>
                </li>
              </ul>
            </section>
          </div>
        </p-tabpanel>
        <p-tabpanel value="2">
          <div class="flow">
            <section>
              <div class="header-list">
                <div class="input-wrapper">
                  <p-iconfield>
                    <p-inputicon styleClass="pi pi-search" />
                    <input pInputText #searchModelInput type="text" (input)="searchModel(searchModelInput.value)" />
                  </p-iconfield>
                </div>
                <button class="flat" (click)="openModelDetailsModal()">
                  <span class="material-icons">add</span>
                  Add
                </button>
              </div>

              <ul class="cs-pattern">
                <li *ngFor="let model of filteredModels; let i = index" [ngClass]="{ 'container-list__item--green': i % 2 === 0 }">
                  <div class="">
                    <p>{{ model.name }}</p>
                  </div>
                  <div class="actions">
                    <button class="border-none" (click)="openModelDetailsModal(model)">
                      <span class="material-icons">edit</span>
                    </button>
                    <button class="border-none" (click)="openModelDetailsProcessModal(model)">
                      <span class="material-icons">build</span>
                    </button>
                  </div>
                </li>
              </ul>
            </section>
          </div>
        </p-tabpanel>
        <p-tabpanel value="3">
          <div class="flow">
            <section class="section-details">
              <div class="header-list">
                <div class="input-wrapper">
                  <p-iconfield>
                    <p-inputicon styleClass="pi pi-search" />
                    <input pInputText #searchModelPropertyInput type="text" (input)="searchModelProperty(searchModelPropertyInput.value)" />
                  </p-iconfield>
                </div>
                <button class="flat" (click)="openModelPropertiesModal()">
                  <span class="material-icons">add</span>
                  Add
                </button>
              </div>

              <ul class="cs-pattern">
                <li
                  class=""
                  *ngFor="let model of filteredModelProperties; let i = index"
                  [ngClass]="{ 'container-list__item--green': i % 2 === 0 }"
                >
                  <div class="">
                    <p>{{ model.name }}</p>
                  </div>
                  <div class="actions">
                    <button class="border-none" (click)="openModelPropertiesModal(model)">
                      <span class="material-icons">edit</span>
                    </button>
                  </div>
                </li>
              </ul>
            </section>
          </div>
        </p-tabpanel>
      </p-tabpanels>
    </p-tabs>
  </div>
</main>
