import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MenuItem } from 'card-software-library';
import { UserAuthenticationService } from './services/user-authentication.service';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  readonly environment = environment.environment;

  title = 'asset-ui';
  isLoginPage = false;

  menuItems: MenuItem[] = [
    {
      displayName: 'Assets',
      routerLink: '/assets',
      redirect: true,
    },
    {
      displayName: 'Administration',
      routerLink: '/admin',
      redirect: false,
      children: [
        {
          displayName: 'Asset Details',
          routerLink: 'admin/assets-details',
          redirect: true,
        },
        {
          displayName: 'Sites',
          routerLink: 'admin/sites',
          redirect: true,
        },
      ],
    },
  ];

  constructor(
    private router: Router,
    private userAuthenticationService: UserAuthenticationService,
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.isLoginPage = event.url === '/login';
      }
    });
  }

  get isLoggedIn() {
    return this.userAuthenticationService.isUserAuthenticated;
  }

  get userLite() {
    return this.userAuthenticationService.userLite;
  }

  onLogout() {
    this.userAuthenticationService.logout();
  }
}
