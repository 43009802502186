import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { AssetPrefix } from '../models/AssetPrefix';

@Injectable({
  providedIn: 'root',
})
export class PrefixProxyService {
  private readonly baseUrl = environment.proxyUrls.assetApi + '/api/v1/prefix';
  constructor(private readonly httpClient: HttpClient) {}

  getPrefixes(): Observable<AssetPrefix[]> {
    return this.httpClient.get<AssetPrefix[]>(`${this.baseUrl}/all`);
  }

  upsertPrefix(category: AssetPrefix): Observable<AssetPrefix> {
    return this.httpClient.post<AssetPrefix>(`${this.baseUrl}`, category);
  }

  deletePrefix(id: string): Observable<AssetPrefix> {
    return this.httpClient.delete<AssetPrefix>(`${this.baseUrl}/${id}`);
  }
}
