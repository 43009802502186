import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AssetProxyService } from '../../proxies/asset.proxy.service';
import { Asset, AssetModelPropertyWithValues } from '../../models/Asset';
import { Form, FormControl, FormGroup, Validators } from '@angular/forms';
import { AssetCategory } from '../../models/AssetCategory';
import { CategoryProxyService } from '../../proxies/category.proxy.service';
import { SiteProxyService } from '../../proxies/site.proxy.service';
import { forkJoin, Observable, of } from 'rxjs';
import { Site } from '../../models/Site';
import { AssetType } from '../../models/AssetType';
import { AssetModel } from '../../models/AssetModel';
import { TypeProxyService } from '../../proxies/types.proxy.service';
import { ModelProxyService } from '../../proxies/model.proxy.service';
import { AssetModelProperty } from '../../models/AssetModelProperty';
import { ModelPropertyProxyService } from '../../proxies/model-property.proxy.service';
import _ from 'lodash';
import { PrefixProxyService } from '../../proxies/prefix.proxy.service';
import { AssetPrefix } from '../../models/AssetPrefix';
import { environment } from '../../../environments/environment';
import { AutoCompleteCompleteEvent } from 'primeng/autocomplete';
import { EnumValues } from 'card-software-library';
import { EnumService } from '../../services/enum.service';

@Component({
  selector: 'app-asset-details',
  templateUrl: './asset-details.component.html',
  styleUrls: ['./asset-details.component.scss'], // Fixing typo: styleUrl -> styleUrls
})
export class AssetDetailsComponent implements OnInit {
  breadCrumsItems = [{ label: 'Assets', route: '/assets' }, { label: 'Asset Detail' }];

  asset: Asset | null = null;
  isLoading = 0;
  isEditing = false;

  get showKnittingMachineDetails(): boolean {
    return this.assetCategory.value?.id === environment.knittingMachineCategoryId;
  }

  get showDyeingMachineDetails(): boolean {
    return this.assetCategory.value?.id === environment.dyeingMachineCategoryId;
  }

  categories: AssetCategory[] = [];
  types: AssetType[] = [];
  sites: Site[] = [];
  prefixes: AssetPrefix[] = [];

  filteredTypes: AssetType[] = [];
  models: AssetModel[] = [];
  filteredModels: AssetModel[] = [];
  propertyModels: AssetModelProperty[] = [];
  filteredPropertyModels: AssetModelProperty[] = [];

  editingProperty: AssetModelProperty | null = null;
  addValues: string[] = [];
  newValue: FormControl<string | null> = new FormControl('');
  disableAddButton: boolean = false;

  assetId: FormControl<string | null> = new FormControl('');
  prefix: FormControl<AssetPrefix | null> = new FormControl(null, [Validators.required]);
  description: FormControl<string | null> = new FormControl('');

  assetCategory: FormControl<AssetCategory | null> = new FormControl(null, [Validators.required]);
  assetType: FormControl<AssetType | null> = new FormControl(null, [Validators.required]);
  assetModel: FormControl<AssetModel | null> = new FormControl(null, [Validators.required]);

  siteReference: FormControl<string | null> = new FormControl('', [Validators.required]);
  assetManufacturerReference: FormControl<string | null> = new FormControl('');
  createdBy: FormControl<string | null> = new FormControl('');
  modifiedBy: FormControl<string | null> = new FormControl('');
  modifiedDate: FormControl<Date> = new FormControl(new Date(), {
    nonNullable: true,
  });

  // Machine form controls
  diameterInches = new FormControl(0);
  gauge = new FormControl(0);
  feeders = new FormControl(0);
  needles = new FormControl(0);

  // Dyeing machine form controls
  numberOfPorts = new FormControl(0);

  //Capacity form controls
  maxCapacity = new FormControl(0);
  minCapacity = new FormControl(0);
  capacityUOM = new FormControl(null);

  knittingMachineDetails: FormGroup = new FormGroup({
    diameterInches: this.diameterInches,
    gauge: this.gauge,
    feeders: this.feeders,
    needles: this.needles,
  });

  dyeingMachineDetails: FormGroup = new FormGroup({
    numberOfPorts: this.numberOfPorts,
  });

  assetCapacity: FormGroup = new FormGroup({
    maxCapacity: this.maxCapacity,
    minCapacity: this.minCapacity,
    capacityUOM: this.capacityUOM,
  });

  formAssetGroup: FormGroup = new FormGroup({
    assetId: this.assetId,
    prefix: this.prefix,
    description: this.description,
    assetCategory: this.assetCategory,
    assetType: this.assetType,
    assetModel: this.assetModel,
    siteReference: this.siteReference,
    assetManufacturerReference: this.assetManufacturerReference,
    createdBy: this.createdBy,
    modifiedBy: this.modifiedBy,
    modifiedDate: this.modifiedDate,
    knittingMachineDetails: this.knittingMachineDetails,
    dyeingMachineDetails: this.dyeingMachineDetails,
    assetCapacity: this.assetCapacity,
  });

  capacityUOMOptions: EnumValues;

  constructor(
    private readonly assetProxyService: AssetProxyService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly categoryProxyService: CategoryProxyService,
    private readonly siteProxyService: SiteProxyService,
    private readonly typeProxyService: TypeProxyService,
    private readonly modelProxyService: ModelProxyService,
    private readonly modelPropertyProxyService: ModelPropertyProxyService,
    private readonly prefixProxyService: PrefixProxyService,
    private readonly enumSevice: EnumService,
  ) {
    this.capacityUOMOptions = this.enumSevice.getEnum('CapacityUOM').values;
  }

  ngOnInit(): void {
    this.isLoading++;
    forkJoin([
      this.categoryProxyService.getCategories(),
      this.siteProxyService.getSites(),
      this.typeProxyService.getTypes(),
      this.modelProxyService.getModels(),
      this.prefixProxyService.getPrefixes(),
    ]).subscribe(([categories, sites, types, models, prefixes]) => {
      this.isLoading--;
      this.categories = categories;
      this.sites = sites;
      this.types = types;
      this.models = models;
      this.prefixes = prefixes;
      this.getAsset();
    });

    this.assetCategory.valueChanges.subscribe((reference) => {
      if (this.asset) {
        this.asset.assetModelParameters = [];
      }

      this.filteredPropertyModels = [];
      this.editingProperty = null;
      if (reference) {
        if (reference.id === environment.knittingMachineCategoryId) {
          this.prefix.setValue(this.prefixes.find((prefix) => prefix.prefix === 'KM') || null, { emitEvent: false });
        }
        if (reference.id === environment.dyeingMachineCategoryId) {
          this.prefix.setValue(this.prefixes.find((prefix) => prefix.prefix === 'DM') || null, { emitEvent: false });
        }
        this.editingProperty = null;
        this.assetType.setValue(null);
        this.assetModel.setValue(null);
        this.populateTypes();
        this.populateModels();
      }
    });

    this.assetType.valueChanges.subscribe((reference) => {
      if (this.asset) {
        this.asset.assetModelParameters = [];
      }
      this.filteredPropertyModels = [];
      this.editingProperty = null;
      if (reference) {
        this.assetModel.setValue(null);
        this.populateModels();
      }
    });

    this.assetModel.valueChanges.subscribe((model) => {
      if (model) {
        this.editingProperty = null;
        if (this.asset) {
          this.asset.assetModelParameters = [];
        }
        this.populateModelProperties(model.id);
      } else {
        this.filteredPropertyModels = [];
        this.editingProperty = null;
        if (this.asset) {
          this.asset.assetModelParameters = [];
        }
      }
    });
  }

  _setOptionsForPrimeNg(value: EnumValues) {
    return Object.keys(value).map((key) => {
      const numKey = parseInt(key);
      return {
        label: value[numKey],
        value: numKey,
      };
    });
  }

  getAsset() {
    this.route.queryParams.subscribe((params) => {
      if (params['assetId'] && params['assetId'] !== 'new') {
        this.isEditing = true;
        this.isLoading++;
        this.assetProxyService.getAssetById(params['assetId']).subscribe((asset) => {
          this.asset = asset;

          const originalParams = asset.assetModelParameters;

          this.formAssetGroup.patchValue(
            {
              assetId: asset?.assetId || '',
              description: asset?.description || '',
              prefix: this.prefixes.find((prefix) => prefix.id === asset.prefixReference) || null,
              siteReference: asset?.siteReference || '',
              assetType: this.types.find((type) => type.id === asset?.assetTypeReference),
              assetCategory: this.categories.find((category) => category.id === asset?.assetCategoryReference) || null,
              assetModel: this.models.find((model) => model.id === asset?.assetModelReference) || null,
              assetManufacturerReference: this.asset?.assetManufacturerReference || '',
              createdBy: this.asset?.createdBy || '',
              modifiedBy: this.asset?.modifiedBy || '',
              modifiedDate: this.asset?.modifiedDate || new Date(),
              knittingMachineDetails: this.asset?.knittingMachineDetails,
              dyeingMachineDetails: this.asset?.dyeingMachineDetails,
              assetCapacity: this.asset?.assetCapacity,
            },
            { emitEvent: false },
          );
          if (asset.assetModelReference) {
            this.populateModelProperties(asset.assetModelReference);
          }
          this.isLoading--;
          this.populateTypes();
          this.populateModels();
          this.asset.assetModelParameters = originalParams;
        });
      } else {
        this.asset = {
          id: '',
          assetId: '',
          description: '',
          assetCategoryReference: '',
          siteReference: '',
          assetTypeReference: '',
          prefixReference: '',
          assetModelReference: '',
          assetManufacturerReference: '',
          assetModelParameters: [],
          createdBy: '',
          modifiedBy: '',
          modifiedDate: new Date(),
        };
      }
    });
  }

  populateTypes() {
    this.filteredTypes = this.types.filter((type) => type.assetCategory?.id === this.assetCategory?.value?.id);
  }

  populateModels() {
    this.filteredModels = this.models.filter((model) => model.assetType?.id === this.assetType?.value?.id);
  }

  populateModelProperties(modelId: string): void {
    this.isLoading++;
    const selectedModel = this.models.find((model) => model.id === modelId);

    if (selectedModel && (selectedModel.modelProperties?.length ?? 0) > 0) {
      this.modelPropertyProxyService
        .getMultipleModelProperties(selectedModel.modelProperties?.map((property) => property.id) ?? [])
        .subscribe(
          (properties) => {
            this.filteredPropertyModels = _.map(properties, (property) => {
              property.defaultValues =
                this.asset?.assetModelParameters.find((param) => param.id === property.id)?.values ?? property.defaultValues;
              return property;
            });
            this.isLoading--;
          },
          (error) => {
            this.filteredPropertyModels = [];
            this.isLoading--;
          },
        );
    } else {
      this.filteredPropertyModels = [];
      this.isLoading--;
    }
  }

  enableEditing(property: AssetModelProperty): void {
    if (property.setValuesAtAssetLevel) {
      this.editingProperty = property;
      this.addValues = property.defaultValues;
    }
  }

  addValueToDefaultValues(): void {
    if (!this.newValue.value) {
      console.warn('Input value is empty');
      return;
    }

    if (this.editingProperty?.multipleValues) {
      this.addValues.push(this.newValue.value.trim());

      this.newValue.setValue('');
    } else {
      if (this.addValues.length >= 1) {
        this.disableAddButton = true;
      } else {
        this.addValues.push(this.newValue.value.trim());
        this.newValue.setValue('');
      }
    }

    this.newValue.setValue('');
  }

  onCancelEdit(): void {
    this.clearEditingState();
  }

  removeValueFromDefaultValues(index: number): void {
    this.addValues.splice(index, 1);
  }

  getValues(values: string[]): string {
    return values.join(', ');
  }

  onSaveValueProperty(property: AssetModelProperty): void {
    this.filteredPropertyModels.map((propertyModel) => {
      if (propertyModel.id === property.id) {
        propertyModel.defaultValues.push(...(this.newValue.value ? [this.newValue.value] : []));
      }
    });
    this.newValue.setValue('');
  }

  clearEditingState(): void {
    this.editingProperty = null;
    this.addValues = [];
    this.newValue.setValue('');
  }

  // #region typeaheads
  filteredCategories: AssetCategory[] = [];
  categoryTypeahead(event: AutoCompleteCompleteEvent) {
    const query = event.query.toLowerCase();
    this.filteredCategories = this.categories
      .filter((t) => t.name.toLowerCase().includes(query))
      .sort((a, b) => a.name.localeCompare(b.name))
      .slice(0, 8);
  }

  typeTypeahead(event: AutoCompleteCompleteEvent) {
    const query = event.query.toLowerCase();
    const filteredTypes = this.types.filter((t) => t.assetCategoryReference === this.assetCategory.value?.id);
    this.filteredTypes = this.filteredTypes
      .filter((t) => t.name.toLowerCase().includes(query))
      .sort((a, b) => a.name.localeCompare(b.name))
      .slice(0, 8);
    if (!this.filteredTypes) this.assetType.setValue(null);
  }

  filterModels: AssetModel[] = [];
  modelTypeahead(event: AutoCompleteCompleteEvent) {
    const query = event.query.trim().toLowerCase();
    const filteredModels = this.models.filter((t) => t.assetType?.id === this.assetType.value?.id);
    this.filterModels = filteredModels
      .filter((t) => t.name.toLowerCase().includes(query))
      .sort((a, b) => a.name.localeCompare(b.name))
      .slice(0, 8);
  }

  prefixTypeahead = (term: string): Observable<AssetPrefix[]> => {
    term = term.trim().toLowerCase();
    if (!term) {
      return of(this.prefixes);
    }

    return of(this.prefixes.filter((prefix) => prefix.prefix.toLowerCase().includes(term)));
  };

  // #region typeaheads

  onAddNewType() {
    this.router.navigate(['/admin/assets-details'], {
      queryParams: { addNewType: true, categoryReference: this.assetCategory.value?.id },
    });
  }

  onAddNewModel() {
    this.router.navigate(['/admin/assets-details'], {
      queryParams: { addNewModel: true, typeReference: this.assetType.value?.id },
    });
  }

  isButtonDisabled(): boolean {
    return (
      this.formAssetGroup.invalid ||
      typeof this.assetType.value === 'string' ||
      typeof this.assetCategory.value === 'string' ||
      typeof this.assetModel.value === 'string'
    );
  }
  onSave() {
    if (this.formAssetGroup.valid) {
      const currentDate = new Date();
      this.modifiedDate.setValue(currentDate);

      const asset: Asset = {
        id: this.asset?.id || '',
        assetId: this.assetId.value || '',
        prefixReference: this.prefix.value?.id || '',
        description: this.description.value || '',
        assetCategoryReference: this.assetCategory.value?.id || '',
        siteReference: this.siteReference.value || '',
        assetTypeReference: this.assetType.value?.id || '',
        assetModelReference: this.assetModel.value?.id || '',
        assetManufacturerReference: this.assetManufacturerReference.value || null,
        assetModelParameters: this.filteredPropertyModels
          .filter((p) => p.setValuesAtAssetLevel)
          .map((property) => ({
            id: property.id,
            values: property.defaultValues,
          })),
        knittingMachineDetails: this.knittingMachineDetails.value,
        dyeingMachineDetails: this.dyeingMachineDetails.value,
        assetCapacity: this.assetCapacity.value,
        createdBy: this.createdBy.value || '',
        modifiedBy: this.modifiedBy.value || '',
        modifiedDate: currentDate,
      };

      this.assetProxyService.upsertAsset(asset).subscribe(
        (asset) => {
          console.log('Asset updated:', asset);
          this.router.navigate(['/assets']);
        },
        (error) => {
          console.error('Error:', error);
        },
      );
    }
  }

  onClose() {
    this.router.navigate(['/assets']);
  }
}
