import { Component, EventEmitter, Input, Output } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent {
  // Input to receive login status from parent component
  @Input() isLoggedIn: boolean = false
  @Input() firstName: string = ''
  @Input() lastName: string = ''
  @Input() env: string = ''

  @Output() logout: EventEmitter<void> = new EventEmitter<void>()

  profileModalOpen: boolean = false

  constructor(public dialog: MatDialog) {}

  openProfileModal(): void {
    this.profileModalOpen = true
  }

  onLogout(): void {
    this.logout.emit()
    this.profileModalOpen = false // Close modal on logout
  }
}
