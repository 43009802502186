import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { UserAuthenticationService } from './services/user-authentication.service';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const userAuthenticationService = inject(UserAuthenticationService);
  const authToken = userAuthenticationService.getStoredJwtToken();
  const excludedUrls = ['/login', '/register'];
  const isExcluded = excludedUrls.some((url) => req.url.includes(url));

  if (isExcluded) {
    return next(req);
  }

  const authReq = req.clone({
    setHeaders: {
      Authorization: authToken ? `Bearer ${authToken}` : '',
    },
  });

  return next(authReq);
};
