<header class="row header">
  <h2>{{ type?.id ? 'Edit Type' : 'Add Type' }}</h2>
  <button (click)="onClose()" class="square mainAction">
    <span class="material-icons white text-white">close</span>
  </button>
</header>
<section class="content">
  <form [formGroup]="formTypeGroup">
    <!-- Name -->
    <div class="form-box">
      <label for="name" ariaRequired="true">Name</label>
      <input pInputText type="text" id="name" formControlName="name" class="w-full" />
    </div>
    <!-- Description -->
    <div class="form-box">
      <label for="description" ariaRequired="true">Description</label>
      <input pInputText type="text" id="description" formControlName="description" class="w-full" />
    </div>
    <!-- Category -->
    <div class="form-box">
      <label for="assetCategoryReference" ariaRequired="true">Category</label>
      <p-dropdown
        id="assetCategoryReference"
        [options]="categories"
        formControlName="assetCategoryReference"
        optionLabel="name"
        optionValue="id"
        placeholder="Select a category"
        [filter]="false"
        class="w-full"
      >
      </p-dropdown>
    </div>

    <!-- Hidden until JWT are ready -->
    <!-- <h3 class="line">History</h3>
    //  <div class="form-box">
    //   <label for="createdBy">Created By</label>
    //   <p>
    //     {{ type?.createdBy }}
    //   </p>
    // </div>
    // <div class="form-box">
    //   <label for="modifiedBy">Modified By</label>
    //   <p>
    //     {{ type?.modifiedBy }}
    //   </p>
    // </div>
    // <div class="form-box">
    //   <label for="modifiedDate"> Modified Date </label>
    //   <p>
    //     {{ modifiedDate.value | date: 'yyyy-MM-dd' }}
    // </div> -->
  </form>
</section>
<footer>
  <button class="mainAction" (click)="onSave()" [disabled]="formTypeGroup.invalid" type="submit">Save</button>
  <button (click)="onClose()">Cancel</button>
</footer>
