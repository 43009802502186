import { Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MenuItem } from '../_models/menu-item';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrl: './nav-menu.component.scss',
})
export class NavMenuComponent implements OnInit {
  @Input() menuItems: MenuItem[] = [];
  hideMenu = false;

  selectedMenuItem: MenuItem | null = null;

  constructor(private router: Router) {}

  findSelectedMenuItem(menuItems: MenuItem[], url: string): MenuItem | null {
    for (let menuItem of menuItems) {
      if (menuItem.routerLink === url) {
        return menuItem;
      }
      if (menuItem.children) {
        const childItem = this.findSelectedMenuItem(menuItem.children, url);
        if (childItem) {
          return childItem;
        }
      }
    }
    return null;
  }

  menuItemSelected(menuItem: MenuItem) {
    console.log('menu item selected', menuItem);
    this.selectedMenuItem = menuItem;
    if (menuItem.redirect) {
      this.router.navigate([menuItem.routerLink]);
    }
  }

  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        console.log(event.url);
        this.hideMenu = event.url === '/login';

        //find the selected menu item
        this.selectedMenuItem = this.findSelectedMenuItem(
          this.menuItems,
          event.url,
        );
        console.log('selectedMenuItem', this.selectedMenuItem);
      }
    });
  }
}
