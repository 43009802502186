import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AssetDetailsComponent } from './components/asset-details/asset-details.component';
import { AssetFamilyDetailsComponent } from './components/asset-management-module/asset-family-details/asset-family-details.component';
import { AssetsComponent } from './components/assets/assets.component';
import { SitesComponent } from './components/sites/sites.component';
import { AssetsDetailsComponent } from './components/assets-details/assets-details.component';
import { LoginComponent } from './components/login/login.component';
import { AuthenticationGuard } from './guards/authentication.guard';
const routes: Routes = [
  {
    path: 'asset-details',
    component: AssetDetailsComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'assets',
    component: AssetsComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'sites',
    redirectTo: '/admin/sites',
    pathMatch: 'full',
  },
  {
    path: 'admin',
    children: [
      {
        path: 'sites',
        component: SitesComponent,
        canActivate: [AuthenticationGuard],
      },
      {
        path: 'assets-details',
        component: AssetsDetailsComponent,
        canActivate: [AuthenticationGuard],
      },
    ],
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: '**',
    redirectTo: 'assets',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
