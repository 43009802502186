import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserAuthenticationService } from '../../services/user-authentication.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class LoginComponent implements OnInit {
  isLoading = false;
  showPassword = false;
  wrongCredentials = false;
  username: FormControl<string | null> = new FormControl('', Validators.required);
  password: FormControl<string | null> = new FormControl('', Validators.required);
  constructor(
    private readonly userAuthenticationService: UserAuthenticationService,
    private readonly router: Router,
  ) {}

  ngOnInit(): void {
    if (this.userAuthenticationService.isUserAuthenticated) {
      this.router.navigate(['/lab-dip-requests']);
    }
  }

  loginFormGroup: FormGroup = new FormGroup({
    username: this.username,
    password: this.password,
  });

  login() {
    if (this.loginFormGroup.valid) {
      this.isLoading = true;
      const loginBody = {
        username: this.loginFormGroup.value.username,
        password: this.loginFormGroup.value.password,
      };
      this.userAuthenticationService.login(loginBody).subscribe((response) => {
        if (response) {
          this.isLoading = false;
          this.router.navigate(['/lab-dip-requests']);
        } else {
          this.wrongCredentials = true;
          this.isLoading = false;
        }
      });
    }
  }
}
