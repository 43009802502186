import { Injectable } from '@angular/core';
import { EnumObject } from 'card-software-library';
import { forkJoin, Observable } from 'rxjs';
import { AssetEnumsProxyService } from '../proxies/asset-enums.proxy.service';

@Injectable({
  providedIn: 'root',
})
export class EnumService {
  private readonly assetEnumLocalStorageKey = 'assetEnums';
  constructor(private readonly assetEnumsProxyService: AssetEnumsProxyService) {}

  public loadEnums(): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      forkJoin([this.assetEnumsProxyService.getAll()]).subscribe({
        next: (response) => {
          localStorage.setItem(this.assetEnumLocalStorageKey, JSON.stringify(response[0]));

          observer.next(true);
          observer.complete();
        },
        error: (error) => {
          console.error('Error loading enums', error);
          observer.next(false);
          observer.complete();
        },
      });
    });
  }

  public getEnum(enumName: string): EnumObject {
    const enumObj = JSON.parse(localStorage.getItem(this.assetEnumLocalStorageKey)!);

    if (!enumObj) {
      this.loadEnums().subscribe({
        next: (response) => {
          if (response) {
            this.getEnum(enumName);
          }
        },
        error: (error) => {
          console.error('Error loading enums', error);
        },
      });
    }
    return enumObj.find((x: { name: string; values: {} }) => x.name === enumName);
  }
}
