import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class KeyService {
  public normalizeKey(event: KeyboardEvent): string | undefined {
    if (event.key) {
      if (event.key === 'Esc') {
        return 'Escape'; // Thanks, Microsoft.
      }
      return event.key;
    }
    const keyCode = event.keyCode ?? event.which;
    return this.lookupKeyCode(keyCode);
  }

  private lookupKeyCode(keyCode: number): string | undefined {
    switch (keyCode) {
      case 9:
        return 'Tab';
      case 13:
        return 'Enter';
      case 27:
        return 'Escape';
      case 38:
        return 'ArrowUp';
      case 40:
        return 'ArrowDown';
      default:
        return undefined;
    }
  }
}
