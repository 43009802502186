<div class="content__wrapper">
  @if (!isLoginPage) {
    <app-nav-menu
      [env]="environment"
      [firstName]="userLite?.firstName || ''"
      [lastName]="userLite?.lastName || ''"
      [isLoggedIn]="isLoggedIn"
      appTitle="Sierra Asset"
      (logout)="onLogout()"
      [menuItems]="menuItems"
    />
  }
  <router-outlet></router-outlet>
</div>
