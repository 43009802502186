import { Injectable } from '@angular/core';
import { jwtDecode } from 'jwt-decode';
import { catchError, map, Observable, of, tap } from 'rxjs';
import { LoginBody } from '../models/user-master/commands/login-body';
import { Router } from '@angular/router';
import { JwtPayload } from '../models/user-master/jwt-payload';
import { UserMasterProxyService } from '../proxies/user-master.proxy.service';

@Injectable({
  providedIn: 'root',
})
export class UserAuthenticationService {
  private readonly tokenKey = 'SierraToken';
  constructor(
    private userMasterProxyService: UserMasterProxyService,
    private router: Router,
  ) {}

  public login(loginBody: LoginBody): Observable<boolean> {
    return this.userMasterProxyService.login(loginBody).pipe(
      tap((response) => {
        localStorage.setItem(this.tokenKey, response.token);
      }),
      map(() => true),
      catchError((error) => {
        console.error('Error logging in', error);
        return of(false);
      }),
    );
  }

  public logout() {
    localStorage.removeItem(this.tokenKey);
    this.router.navigate(['/login']);
  }

  public get userLite() {
    const token = this.getStoredJwtToken();
    if (!token) {
      return null;
    }

    // Decode the token and map to user details
    const { FirstName, LastName, Email, Username }: JwtPayload = jwtDecode<JwtPayload>(token);
    return { firstName: FirstName, lastName: LastName, email: Email, username: Username };
  }

  public get isUserAuthenticated(): boolean {
    const token = this.getStoredJwtToken();
    if (!token) {
      return false;
    }
    const isTokenExpired = this.isTokenExpired(token);
    if (isTokenExpired) {
      this.logout();
      return false;
    }
    return true;
  }

  getStoredJwtToken() {
    return localStorage.getItem(this.tokenKey);
  }

  isTokenExpired(token: string) {
    try {
      const { exp }: { exp: number } = jwtDecode(token);
      return exp * 1000 < Date.now();
    } catch (e) {
      console.error('Error decoding token', e);
      return true;
    }
  }
}
