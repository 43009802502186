import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './shared/header/header.component';
import { NavMenuComponent } from './shared/nav-menu/nav-menu.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragReorderDirective } from './shared/directives/drag-reorder.directive';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { DialogModule } from '@angular/cdk/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BaseModalComponent } from './shared/base-modal/base-modal.component';
import { AssetDetailsComponent } from './components/asset-details/asset-details.component';
import { AssetFamilyDetailsComponent } from './components/asset-management-module/asset-family-details/asset-family-details.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AssetsComponent } from './components/assets/assets.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    NavMenuComponent,
    DragReorderDirective,
    BaseModalComponent,
    AssetDetailsComponent,
    AssetFamilyDetailsComponent,
    AssetsComponent,
    SidebarComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatIconModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    MatTooltipModule,
    MatInputModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    BrowserAnimationsModule,
    DialogModule,
    HttpClientModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
