import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AssetType } from '../models/AssetType';

@Injectable({
  providedIn: 'root',
})
export class TypeProxyService {
  private readonly baseUrl = environment.proxyUrls.assetApi + '/api/v1/type';
  constructor(private readonly httpClient: HttpClient) {}

  getTypes(): Observable<AssetType[]> {
    return this.httpClient.get<AssetType[]>(`${this.baseUrl}/all`);
  }

  upsertType(type: AssetType): Observable<AssetType> {
    return this.httpClient.post<AssetType>(`${this.baseUrl}`, type);
  }
}
