<main>
  <div class="content">
    <h1>Asset Family</h1>
    <section>
      <h3 class="line">General</h3>
      <div class="form-box">
        <label for="">Name</label>
        <input type="text" value="Family 1" />
      </div>
      <div class="form-box">
        <p class="informative">
          (This will auto display the what the user needs to fill out on the
          section below)
        </p>
        <label for="">Family Type</label>
        <select name="" id="">
          <option value="1">Knitting</option>
          <option value="2">Dyeing</option>
          <option value="3">Finishing</option>
        </select>
      </div>
      <div class="form-box">
        <label for="">Description</label>
        <textarea
          name=""
          id=""
          cols="40"
          rows="5"
          value="Description for the family"
        ></textarea>
      </div>
    </section>
    <section>
      <h3 class="line">Knitting Info</h3>
      <div class="row">
        <div class="form-box">
          <label for="dia">Dia</label>
          <input type="text" id="dia" name="dia" value="30" />
        </div>

        <div class="form-box">
          <label for="gauge">Gauge</label>
          <input type="text" id="gauge" name="gauge" value="18" />
        </div>

        <div class="form-box">
          <label for="needles">Needles</label>
          <input type="text" id="needles" name="needles" value="3360" />
        </div>

        <div class="form-box">
          <label for="feeders">Feeders</label>
          <input type="text" id="feeders" name="feeders" value="60" />
        </div>

        <div class="form-box">
          <label for="yarnTension">Yarn Tension cN</label>
          <input type="text" id="yarnTension" name="yarnTension" value="10" />
        </div>

        <div class="form-box">
          <label for="machineRev">Machine Rev</label>
          <input type="text" id="machineRev" name="machineRev" value="2873" />
        </div>

        <div class="form-box">
          <label for="machineRotation">Machine Rotation</label>
          <input
            type="text"
            id="machineRotation"
            name="machineRotation"
            value="Anti Clock"
          />
        </div>

        <div class="form-box">
          <label for="rpm">RPM</label>
          <input type="text" id="rpm" name="rpm" value="20" />
        </div>
      </div>
    </section>
  </div>
</main>
