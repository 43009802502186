import { Component } from '@angular/core';
import { MenuItem } from './shared/_models/menu-item';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  title = 'asset-ui';

  menuItems: MenuItem[] = [
    {
      displayName: 'Assets',
      routerLink: '/assets',
      redirect: true,
    },
    {
      displayName: 'Administration',
      routerLink: '/administration',
      redirect: false,
      children: [
        {
          displayName: 'Asset Details',
          routerLink: '/asset-details-admin',
          redirect: false,
        },
        {
          displayName: 'Plant Locations',
          routerLink: '/plant-locations-admin',
          redirect: false,
        },
      ],
    },
  ];
}
