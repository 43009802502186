export enum PropertyType {
  String,
  Number,
  Boolean,
  DateTime,
  Float,
}

export const PropertyTypeDisplayLabels: Record<PropertyType, string> = {
  [PropertyType.String]: 'Text',
  [PropertyType.Number]: 'Integer Number',
  [PropertyType.Boolean]: 'Yes/No',
  [PropertyType.DateTime]: 'Date/Time',
  [PropertyType.Float]: 'Decimal Number',
};
