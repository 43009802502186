<header class="row header">
  <h2>Edit Model Processes</h2>
  <button (click)="onClose()" class="square mainAction">
    <span class="material-icons white text-white">close</span>
  </button>
</header>

<section class="content">
  <p>
    The following processes have been set for the Asset Type: <a>{{ model?.assetType?.name }}</a
    >. <br />
    Select the checkboxes for the processes that this asset model is capable of working with. <br />
    If a process is selected, all of its child processes will be automatically selected. <br />
    Similarly, if all subprocesses of a process are selected, the process itself will be automatically selected.
  </p>

  <div class="row">
    <button (click)="openAll()" class="open-all-button">Open All</button>
    <button (click)="closeAll()" class="close-all-button">Close All</button>
  </div>

  <div *ngIf="model?.assetType?.assetProcesses?.length === 0" class="empty-message">
    <p>No processes added. Please add them at type tab for Asset Type : {{ model?.assetType?.name }}</p>
  </div>

  <ul *ngFor="let process of model?.assetType?.assetProcesses" class="process-list">
    <li>
      <div class="process-header row">
        <button (click)="toggleProcess(process.id)" class="square" *ngIf="!!process.subProcesses.length">
          <span class="material-icons toggle-arrow">
            {{ showProcessesDict[process.id] ? 'expand_less' : 'expand_more' }}
          </span>
        </button>

        <p>{{ process.name }}</p>
        <p-checkbox 
          [binary]="true"
          [ngModel]="!isProcessExcluded(process.id)"
          (onChange)="selectProcess(process.id)"
        />
      </div>

      <div *ngIf="showProcessesDict[process.id]" class="subprocess-container">
        <ul class="subprocess-list">
          <li *ngFor="let subProcess of process.subProcesses">
            <div class="subprocess-header row">
              <button (click)="toggleProcess(subProcess.id)" class="square me-2" *ngIf="!!subProcess.subProcesses.length">
                <span class="material-icons toggle-arrow">
                  {{ showProcessesDict[subProcess.id] ? 'expand_less' : 'expand_more' }}
                </span>
              </button>
              <p>{{ subProcess.name }}</p>

              <p-checkbox 
                [binary]="true"
                [ngModel]="!isProcessExcluded(subProcess.id)"
                (onChange)="selectProcess(subProcess.id)"
              />
            </div>

            <div *ngIf="showProcessesDict[subProcess.id]" class="nested-subprocess-container">
              <ul class="nested-subprocess-list">
                <li *ngFor="let nestedSubProcess of subProcess.subProcesses">
                  <div class="nested-subprocess-header row">
                    <p>{{ nestedSubProcess.name }}</p>

                    <!-- <input
                      type="checkbox"
                      (change)="selectProcess(nestedSubProcess.id)"
                      [checked]="!isProcessExcluded(nestedSubProcess.id)"
                    /> -->
                    <p-checkbox 
                      [binary]="true"
                      [ngModel]="!isProcessExcluded(nestedSubProcess.id)"
                      (onChange)="selectProcess(nestedSubProcess.id)"
                    />
                  </div>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </li>
  </ul>
</section>

<footer>
  <button class="mainAction" (click)="onSave()" [disabled]="saving" type="submit">Save</button>
  <button (click)="onClose()">Cancel</button>
</footer>
