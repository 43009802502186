<main>
  <loading-overlay [isLoading]="isLoading > 0"></loading-overlay>
  <header>
    <h1>{{ asset?.assetId ? asset?.assetId : 'New Asset' }}</h1>
    <div class="buttons">
      <button class="mainAction" (click)="onSave()" [disabled]="isButtonDisabled()" type="submit">Save</button>
      <button (click)="onClose()">Cancel</button>
    </div>
  </header>

  <p-breadcrumb class="max-w-full" [model]="breadCrumsItems">
    <ng-template #item let-item>
      <ng-container *ngIf="item.route; else elseBlock">
        <a [routerLink]="item.route" class="p-breadcrumb-item-link">
          <span [ngClass]="[item.icon ? item.icon : '', 'text-color']"></span>
          <span class="text-primary">{{ item.label }}</span>
        </a>
      </ng-container>
      <ng-template #elseBlock>
        <a class="p-breadcrumb-current-item-link">
          <span class="text-color">{{ item.label }}</span>
        </a>
      </ng-template>
    </ng-template>
  </p-breadcrumb>

  <div class="content" *ngIf="asset">
    <section>
      <h3 class="line">General Information</h3>
      <form [formGroup]="formAssetGroup" class="mt__0">
        <div class="grid grid-cols-12 grid__gap__2">
          <div class="col-span-6">
            <!-- Asset ID -->
            <div class="form-box" *ngIf="isEditing">
              <label for="assetId">Asset ID</label>
              <p>{{ asset.assetId }}</p>
            </div>
            <!-- Asset Prefix -->
            <div class="form-box">
              <label for="" class="required">Asset Prefix</label>
              <input class="p-inputtext p-component p-filled w-full" id="prefix" [value]="prefix.value?.prefix" [readonly]="true" />
              <p *ngIf="prefix.value">{{ prefix.value.description }}</p>
            </div>
            <!-- Asset Description -->
            <div class="form-box">
              <label for="">Asset Description</label>
              <textarea pTextarea rows="5" cols="30" id="description" formControlName="description" class="w-full"></textarea>
            </div>
            <!-- Category -->
            <div class="form-box">
              <label for="assetCategory" class="required">Category</label>
              <p-autocomplete
                formControlName="assetCategory"
                optionLabel="name"
                [dropdown]="true"
                [suggestions]="filteredCategories"
                (completeMethod)="categoryTypeahead($event)"
                class="w-full"
              />
            </div>
            <!-- Type -->
            <div class="form-box">
              <label for="assetType" class="required">Type</label>
              <p *ngIf="!assetCategory.value" class="cursor-not-allowed">Add a category first</p>
              <ng-container *ngIf="assetCategory.value?.id">
                <p-autocomplete
                  formControlName="assetType"
                  optionLabel="name"
                  [dropdown]="true"
                  [suggestions]="filteredTypes"
                  (completeMethod)="typeTypeahead($event)"
                  class="w-full"
                />
                <ng-template #addNewType>
                  <a id="addNewType" (click)="onAddNewType()">Add New Type</a>
                </ng-template>
              </ng-container>
            </div>
            <!-- Model -->
            <div class="form-box">
              <label for="assetModelReference" class="required">Model</label>
              <p *ngIf="!assetCategory.value" class="cursor-not-allowed">Add a type first</p>
              <p-autocomplete
                *ngIf="assetType.value?.id"
                formControlName="assetModel"
                optionLabel="name"
                [dropdown]="true"
                [suggestions]="filterModels"
                (completeMethod)="modelTypeahead($event)"
                class="w-full"
              />
            </div>
            <ng-template #addTypeFirst>
              <p class="cursor-not-allowed">Add a type first</p>
            </ng-template>
            <!-- <div class="form-box">
              <label for="assetManufacturerReference"> Manufacturer </label>
              <input type="text" id="assetManufacturerReference" formControlName="assetManufacturerReference" />
            </div> -->
          </div>
        </div>
      </form>
    </section>
    <!-- Location -->
    <section>
      <h3 class="line">Location</h3>
      <div class="grid grid-cols-12">
        <div class="col-span-6">
          <div class="form-box">
            <label for="siteCode" class="required">Site Code</label>
            <p-dropdown
              id="siteCode"
              [options]="sites"
              [formControl]="siteReference"
              optionLabel="name"
              optionValue="id"
              placeholder="Select a site"
              [filter]="false"
              class="w-full"
            />
          </div>
        </div>
      </div>
    </section>
    <!-- Knitting Machine Details -->
    <section *ngIf="showKnittingMachineDetails">
      <h3 class="line machineInfoTitle">Knitting Machine Details</h3>
      <form [formGroup]="knittingMachineDetails">
        <div class="grid grid-cols-12">
          <div class="col-span-3">
            <div class="form-box">
              <label for="diameterInches">Diameter</label>
              <input pInputText type="number" id="diameterInches" formControlName="diameterInches" class="w-full" />
            </div>
          </div>
          <div class="col-span-3">
            <div class="form-box">
              <label for="gauge">Gauge</label>
              <input pInputText type="number" id="gauge" formControlName="gauge" class="w-full" />
            </div>
          </div>
          <div class="col-span-3">
            <div class="form-box">
              <label for="feeders">Feeders</label>
              <input pInputText type="text" id="feeders" formControlName="feeders" class="w-full" />
            </div>
          </div>
          <div class="col-span-3">
            <div class="form-box">
              <label for="needles">Needles</label>
              <input pInputText type="text" id="needles" formControlName="needles" class="w-full" />
            </div>
          </div>
        </div>
      </form>
    </section>
    <section *ngIf="showDyeingMachineDetails">
      <h3 class="line machineInfoTitle">Dyeing Machine Details</h3>
      <form [formGroup]="dyeingMachineDetails">
        <div class="form-box">
          <label for="NoOfPorts">Number of Ports</label>
          <input type="number" id="NoOfPorts" formControlName="numberOfPorts" />
        </div>
      </form>
    </section>
    <!-- Asset Capacity -->
    <section *ngIf="showDyeingMachineDetails || showKnittingMachineDetails">
      <h3 class="line">Asset Capacity</h3>
      <form [formGroup]="assetCapacity">
        <div>
          <div class="grid grid-cols-12">
            <div class="col-span-6">
              <div class="grid grid-cols-12">
                <div class="col-span-4">
                  <div class="form-box">
                    <label for="maxCapacity">Max Capacity</label>
                    <input pInputText type="number" id="maxCapacity" formControlName="maxCapacity" class="w-full" />
                  </div>
                </div>
                <div class="col-span-4">
                  <div class="form-box">
                    <label for="minCapacity">Min Capacity</label>
                    <input pInputText type="number" id="minCapacity" formControlName="minCapacity" class="w-full" />
                  </div>
                </div>
                <div class="col-span-4">
                  <div class="form-box">
                    <label for="capacityUOM">Capacity UOM</label>
                    <p-dropdown
                      id="capacityUOM"
                      [options]="_setOptionsForPrimeNg(capacityUOMOptions)"
                      optionLabel="label"
                      optionValue="value"
                      formControlName="capacityUOM"
                      [filter]="false"
                      class="w-full"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </section>
    <!-- Model Properties -->
    <section>
      <h3 class="line">Model Properties</h3>
      <ul *ngIf="filteredPropertyModels?.length; else noProperties" class="flex flex-col gap-2">
        <li
          *ngFor="let property of filteredPropertyModels"
          class="justify-between max-w-fit flex items-center font-medium px-2 py-1 rounded-full border-2"
          [ngClass]="{
            'bg-gray-300 text-gray-500 cursor-not-allowed': !property.setValuesAtAssetLevel,
            'bg-white text-black cursor-pointer': property.setValuesAtAssetLevel,
          }"
          (click)="enableEditing(property)"
        >
          <p>{{ property.name }}: {{ getValues(property.defaultValues) }}</p>
        </li>
      </ul>

      <div *ngIf="editingProperty" class="mt-2">
        <small class="block text-sm font-medium text-gray-700">
          {{ editingProperty.multipleValues ? 'Add Default Values:' : 'Add a Single Default Value:' }}
        </small>
        <div class="flex gap-2 mt-1">
          <input
            pInputText
            type="text"
            [formControl]="newValue"
            placeholder="Enter a value"
            [hidden]="!editingProperty.multipleValues && addValues.length >= 1"
            (keydown.enter)="addValueToDefaultValues()"
            (keydown.escape)="onCancelEdit()"
          />
          <button
            type="button"
            (click)="addValueToDefaultValues()"
            [disabled]="!editingProperty.multipleValues && addValues.length >= 1"
            class="square"
          >
            <span class="material-icons">add</span>
          </button>
        </div>

        <p *ngIf="!editingProperty?.multipleValues && addValues.length >= 1" class="text-red-500 mt-2">
          This property only allows 1 value.
        </p>

        <ul class="mt-2">
          <li
            *ngFor="let value of addValues; let i = index"
            class="py-2 pl-4 flex items-center justify-between bg-gray-100 rounded-md mb-1 w-fit"
          >
            {{ value }}
            <button *ngIf="editingProperty" type="button" (click)="removeValueFromDefaultValues(i)" class="labels mx-4">
              <span class="material-icons">close</span>
            </button>
          </li>
        </ul>

        <div class="flex gap-2 mt-2">
          <button type="button" (click)="onCancelEdit()">Close</button>
        </div>
      </div>

      <ng-template #noProperties>
        <p class="cursor-not-allowed mt-3 text-red-500">No properties found for the selected model.</p>
      </ng-template>
      <ng-template #editProperty>
        <p>Edit Property</p>
      </ng-template>
    </section>
  </div>
</main>
