import { Component, Injector, Input, OnInit } from '@angular/core';
import { BaseModalComponent } from 'card-software-library';
import { AssetType, AssetProcess } from '../../../models/AssetType';
import { TypeProxyService } from '../../../proxies/types.proxy.service';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { AssetCategory } from '../../../models/AssetCategory';

@Component({
  selector: 'app-type-details-process-modal',
  templateUrl: './type-details-process-modal.component.html',
  styleUrl: './type-details-process-modal.component.scss',
})
export class TypeDetailsProcessModalComponent extends BaseModalComponent<AssetType | null> implements OnInit {
  @Input() type: AssetType | null = null;
  @Input() categories: AssetCategory[] = [];

  showProcessesDict: Record<string, boolean> = {};
  showSubProcessesDict: Record<string, boolean> = {}; 

  constructor(
    injector: Injector,
    private readonly typeProxyService: TypeProxyService,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    if (this.type) {
      this.formTypeGroup.patchValue({
        id: this.type.id,
        name: this.type.name,
        description: this.type.description,
        assetCategoryReference: this.type.assetCategory?.id || '',
      });

      // Populate assetProcesses
      this.type.assetProcesses.forEach((process) => this.assetProcesses.push(this.createProcessGroup(process)));
    }
  }

  name: FormControl<string | null> = new FormControl('', [Validators.required]);
  description: FormControl<string | null> = new FormControl('', [Validators.required]);
  assetCategoryReference: FormControl<string | null> = new FormControl('', [Validators.required]);

  // Explicitly define the type of FormArray to ensure TypeScript understands the structure.
  assetProcesses: FormArray<FormGroup> = new FormArray<FormGroup>([]);

  formTypeGroup: FormGroup = new FormGroup({
    name: this.name,
    description: this.description,
    assetCategoryReference: this.assetCategoryReference,
    assetProcesses: this.assetProcesses,
  });

  toggleSubSubProcess(id: string) {
    this.showSubProcessesDict[id] = !this.showSubProcessesDict[id];
  }
  
  addNestedSubProcess(processIndex: number, subProcessIndex: number) {
    const process = this.assetProcesses.at(processIndex) as FormGroup;
    const subProcesses = process.get('subProcesses') as FormArray<FormGroup>;
    const subProcess = subProcesses.at(subProcessIndex) as FormGroup;
    const nestedSubProcesses = subProcess.get('subProcesses') as FormArray<FormGroup>;
  
    nestedSubProcesses.push(this.createProcessGroup());
  }
  
  updateNestedSubProcessName(processIndex: number, subProcessIndex: number, nestedIndex: number, value: string) {
    const process = this.assetProcesses.at(processIndex) as FormGroup;
    const subProcesses = process.get('subProcesses') as FormArray<FormGroup>;
    const subProcess = subProcesses.at(subProcessIndex) as FormGroup;
    const nestedSubProcesses = subProcess.get('subProcesses') as FormArray<FormGroup>;
    const nestedSubProcess = nestedSubProcesses.at(nestedIndex) as FormGroup;
  
    nestedSubProcess.get('name')?.setValue(value);
  }
  
  deleteNestedSubProcess(processIndex: number, subProcessIndex: number, nestedIndex: number) {
    const process = this.assetProcesses.at(processIndex) as FormGroup;
    const subProcesses = process.get('subProcesses') as FormArray<FormGroup>;
    const subProcess = subProcesses.at(subProcessIndex) as FormGroup;
    const nestedSubProcesses = subProcess.get('subProcesses') as FormArray<FormGroup>;
  
    nestedSubProcesses.removeAt(nestedIndex);
  }

  createProcessGroup(process: AssetProcess = { id: '', name: '', description: '', subProcesses: [] }): FormGroup {
    return new FormGroup({
      id: new FormControl(process.id),
      name: new FormControl(process.name, Validators.required),
      description: new FormControl(process.description),
      subProcesses: new FormArray<FormGroup>(process.subProcesses.map((subProcess) => this.createProcessGroup(subProcess))),
    });
  }

  addProcess() {
    this.assetProcesses.push(this.createProcessGroup());
  }

  addSubProcess(index: number) {
    const process = this.assetProcesses.at(index) as FormGroup;
    const subProcesses = process.get('subProcesses') as FormArray<FormGroup>;
    subProcesses.push(this.createProcessGroup());
  }

  removeProcess(index: number) {
    this.assetProcesses.removeAt(index);
  }

  removeSubProcess(processIndex: number, subProcessIndex: number) {
    const process = this.assetProcesses.at(processIndex) as FormGroup;
    const subProcesses = process.get('subProcesses') as FormArray<FormGroup>;
    subProcesses.removeAt(subProcessIndex);
  }

  // New methods to handle changes in process and subprocess names

  updateProcessName(index: number, value: string) {
    const process = this.assetProcesses.at(index) as FormGroup;
    process.get('name')?.setValue(value);
  }

  toggleSubProcess(id: string) {
    this.showProcessesDict[id] = !this.showProcessesDict[id];
    // this.assetProcesses.value[index].showSubProcesses = !this.assetProcesses.value[index].showSubProcesses;
  }

  deleteProcess(index: number) {
    this.assetProcesses.removeAt(index);
  }

  updateSubProcessName(processIndex: number, subProcessIndex: number, value: string) {
    const process = this.assetProcesses.at(processIndex) as FormGroup;
    const subProcesses = process.get('subProcesses') as FormArray<FormGroup>;
    const subProcess = subProcesses.at(subProcessIndex) as FormGroup;
    subProcess.get('name')?.setValue(value);
  }

  deleteSubProcess(processIndex: number, subProcessIndex: number) {
    const process = this.assetProcesses.at(processIndex) as FormGroup;
    const subProcesses = process.get('subProcesses') as FormArray<FormGroup>;
    subProcesses.removeAt(subProcessIndex);
  }

  onClose() {
    this.close({ action: 'close', data: null });
  }

  onSave() {
    if (this.formTypeGroup.valid && this.type && this.type.assetCategory) {
      this.type.assetProcesses = this.assetProcesses.value;
      this.type.assetCategoryReference = this.type.assetCategory.id;

      this.typeProxyService.upsertType(this.type).subscribe({
        next: (type) => {
          this.close({ action: 'save', data: this.type });
        },
        error: (error) => {
          console.error('Error saving type', error);
        },
      });
    } else {
      console.log('Form is invalid');
    }
  }

  closeAll() {
    // Close all main processes
    this.assetProcesses.controls.forEach((_, index) => {
      const process = this.assetProcesses.at(index) as FormGroup;
      const processId = process.get('id')?.value;
  
      if (processId) {
        this.showProcessesDict[processId] = false;
      }
  
      // Close all subprocesses for the current process
      const subProcesses = process.get('subProcesses') as FormArray<FormGroup>;
      subProcesses.controls.forEach((_, subIndex) => {
        const subProcessId = subProcesses.at(subIndex).get('id')?.value;
        if (subProcessId) {
          this.showSubProcessesDict[subProcessId] = false;
        }
      });
    });
  }
  
  // Open all processes and subprocesses
  openAll() {
    // Open all main processes
    this.assetProcesses.controls.forEach((_, index) => {
      const process = this.assetProcesses.at(index) as FormGroup;
      const processId = process.get('id')?.value;
  
      if (processId) {
        this.showProcessesDict[processId] = true;
      }
  
      // Open all subprocesses for the current process
      const subProcesses = process.get('subProcesses') as FormArray<FormGroup>;
      subProcesses.controls.forEach((_, subIndex) => {
        const subProcessId = subProcesses.at(subIndex).get('id')?.value;
        if (subProcessId) {
          this.showSubProcessesDict[subProcessId] = true;
        }
      });
    });
  }
  

  // Helper method to safely get input value from change event
  getInputValue(event: Event): string {
    const target = event.target as HTMLInputElement;
    return target?.value || '';
  }
}
