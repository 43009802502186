<header class="row header">
  <h2>{{ category?.id ? 'Edit Category' : 'Add Category' }}</h2>
  <button (click)="onClose()" class="square mainAction">
    <span class="material-icons white text-white">close</span>
  </button>
</header>
<section class="content">
  <form [formGroup]="formGroup">
    <!-- Name -->
    <div class="form-box">
      <label for="name" ariaRequired="true">Name</label>
      <input pInputText type="text" id="name" formControlName="name" class="w-full" />
    </div>
    <!-- Description -->
    <div class="form-box">
      <label for="description" ariaRequired="true">Description</label>
      <input pInputText type="text" id="description" formControlName="description" class="w-full" />
    </div>

    <!-- Hidden until JWT are ready -->
    <!-- <h3 class="line">History</h3>
      <div class="form-box">
      <label for="createdBy">Created By</label>
      <p>
        {{ category?.createdBy }}
      </p>
    </div>
    <div class="form-box">
      <label for="modifiedBy">Modified By</label>
      <p>
        {{ category?.modifiedBy }}
      </p>
    </div>
    <div class="form-box">
      <label for="modifiedDate"> Modified Date </label>
      <p>
        {{ modifiedDate.value | date: 'yyyy-MM-dd' }}
      </p>
    </div> -->
  </form>
</section>
<footer>
  <button class="mainAction" (click)="onSave()" type="submit" [disabled]="formGroup.invalid">Save</button>
  <button (click)="onClose()">Cancel</button>
</footer>
