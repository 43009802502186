import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { EnumObject } from 'card-software-library';
@Injectable({
  providedIn: 'root',
})
export class AssetEnumsProxyService {
  private readonly baseUrl = environment.proxyUrls.assetApi + '/api/v1/enums';

  constructor(private readonly httpClient: HttpClient) {}

  public getAll(): Observable<EnumObject[]> {
    return this.httpClient.get<EnumObject[]>(`${this.baseUrl}`);
  }
}
