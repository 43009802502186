<main>
  <loading-overlay [isLoading]="isLoading > 0"></loading-overlay>
  <div class="content">
    <h1 class="line float__button">
      Assets
      <div class="button__wrapper">
        <button class="flat" (click)="redirectToAssetDetails('new')">
          <span class="material-icons">add</span>
          Add
        </button>
      </div>
    </h1>

    <div class="grid grid-cols-12 grid__gap__2">
      <div class="col-span-12 md:col-span-3 xl:col-span-3">
        <p-card>
          <ng-template #header>
            <div class="flex items-center gap-2">
              <span>Filters</span>
            </div>
          </ng-template>

          <form action="">
            <div class="form-box">
              <label for="">Asset Id</label>
              <input pInputText class="w-full" [formControl]="searchTermControl" type="text" />
            </div>
          </form>

          <ng-template #footer>
            <div class="flex gap-4 mt__3">
              <button class="mainAction" (click)="search()">Apply</button>
            </div>
          </ng-template>
        </p-card>
      </div>

      <div class="col-span-12 md:col-span-9 xl:col-span-9">
        <p-table [value]="assets" [paginator]="true" [rows]="5" [rowsPerPageOptions]="[5, 10, 20]">
          <ng-template pTemplate="header">
            <tr class="primary__header">
              <td pSortableColumn="assetId" style="width: 115px">Asset Id <p-sortIcon field="assetId" /></td>
              <td pSortableColumn="assetCategoryName">Category <p-sortIcon field="assetCategoryName" /></td>
              <td pSortableColumn="assetTypeName">Type <p-sortIcon field="assetTypeName" /></td>
              <td pSortableColumn="assetModelName">Model <p-sortIcon field="assetModelName" /></td>
              <td pSortableColumn="siteCode" style="width: 125px">Site Code <p-sortIcon field="siteCode" /></td>
              <td pSortableColumn="siteName">Site Name <p-sortIcon field="siteName" /></td>
              <td pSortableColumn="description">Description <p-sortIcon field="description" /></td>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-asset>
            <tr>
              <td>
                <a (click)="redirectToAssetDetails(asset.assetId)">{{ asset.assetId }}</a>
              </td>
              <td>{{ asset.assetCategoryName }}</td>
              <td>{{ asset.assetTypeName }}</td>
              <td>{{ asset.assetModelName }}</td>
              <td>{{ asset.siteCode }}</td>
              <td>{{ asset.siteName }}</td>
              <td>{{ asset.description }}</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</main>
