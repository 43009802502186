import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AssetDetailsComponent } from './components/asset-details/asset-details.component';
import { AssetFamilyDetailsComponent } from './components/asset-management-module/asset-family-details/asset-family-details.component';
import { AssetsComponent } from './components/assets/assets.component';
const routes: Routes = [
  {
    path: 'asset-details',
    component: AssetDetailsComponent,
  },
  {
    path: 'assets',
    component: AssetsComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
