import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { FloatLabelModule } from 'primeng/floatlabel';
import { InputTextModule } from 'primeng/inputtext';
import { InputNumberModule } from 'primeng/inputnumber';
import { CheckboxModule } from 'primeng/checkbox';
import { TagModule } from 'primeng/tag';
import { TableModule } from 'primeng/table';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { PasswordModule } from 'primeng/password';
import { CardModule } from 'primeng/card';
import { TextareaModule } from 'primeng/textarea';
import { InputIcon } from 'primeng/inputicon';
import { IconField } from 'primeng/iconfield';
import { DropdownModule } from 'primeng/dropdown';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { TabsModule } from 'primeng/tabs';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ButtonModule,
    FloatLabelModule,
    InputNumberModule,
    InputTextModule,
    CheckboxModule,
    TagModule,
    TableModule,
    BreadcrumbModule,
    PasswordModule,
    CardModule,
    TextareaModule,
    InputIcon,
    IconField,
    DropdownModule,
    AutoCompleteModule,
    TabsModule,
  ],
  exports: [
    ButtonModule,
    FloatLabelModule,
    InputNumberModule,
    InputTextModule,
    CheckboxModule,
    TagModule,
    TableModule,
    BreadcrumbModule,
    PasswordModule,
    CardModule,
    TextareaModule,
    InputIcon,
    IconField,
    DropdownModule,
    AutoCompleteModule,
    TabsModule,
  ],
})
export class PrimengModule {}
